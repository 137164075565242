<template>
  <div class="dynamic-tags-modal">
    <div class="dynamic-tags-modal__content">
      <div class="body">
        <div class=" u-padding-x-8 u-margin-y-4 message-details">
          <ar-field
            v-for="tag of fallbackDynamicTagsForScratchEmailMessage"
            :key="tag"
            :label="tag"
            class="u-margin-bottom-7"
            :error-messages="[veeErrors.first(tag)]"
          >
            <ar-input
              :placeholder="`Enter fallback value of ${tag}`"
              :value="getFallbackDynamicTagValue(tag)"
              @blur="handleFallbackDynamicTagsInput(convertToCamelCase(tag), ...arguments)"
              v-validate="{
                required: true
              }"
              :data-vv-name="tag"
              :data-vv-as="`${tag} fallback tag`"
              :data-test-id="`fallback-dynamic-tag-${tag}`"
            />
          </ar-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

import { convertToCamelCase } from '@/utils/helpers';
export default {
  name: 'DynamicTagFallbackModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      scratchEmail: state => state.message.scratchEmail,
      fallbackDynamicTagsForScratchEmailMessage: state => state.message.fallbackDynamicTagsForScratchEmailMessage
    }),
    ...mapGetters({})
  },
  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
      }
    }
  },

  methods: {
    ...mapMutations(['message/PATCH_SCRATCH_EMAIL_MESSAGE']),
    ...mapActions({}),
    async initEmailEditor() {},
    handleModalClose() {
      this.$emit('close');
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    handleFallbackDynamicTagsInput(tagName, val) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          fallbackDynamicTags: {
            [tagName]: val.target.value
          }
        }
      });
    },
    getFallbackDynamicTagValue(tagName) {
      if (!!this.scratchEmailMessage.meta.fallbackDynamicTags) {
        return this.scratchEmailMessage.meta.fallbackDynamicTags[convertToCamelCase(tagName)] || '';
      }
      return '';
    },
    convertToCamelCase(str) {
      return convertToCamelCase(str);
    }
  }
};
</script>

<style lang="scss" scoped>
.dynamic-tags-modal {
  background: #f3f6fa;
  padding: 30px 40px 50px;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 660px;
    width: 100%;
  }

  .body {
    max-height: 508px;
    overflow: auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-top: 48px;
    width: 100%;
    max-width: 400px;
    .schedule-message {
      width: 100%;
    }
    .message-details {
      width: 100%;
      .message {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .message-name {
        margin-bottom: 8px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    padding: 0 30px;
  }
  .error-message {
    white-space: normal;
    word-break: break-word;
  }
}
</style>
