<template>
  <section class="message-conversions">
    <div class="message-conversions__content">
      <ar-snackbar
        v-if="!isCalculationsFinished"
        type="success"
        message="Conversion data is being calculated. Your data will be available soon."
        :style="{ width: '100%', marginBottom: '20px' }"
      />

      <div class="message-conversions__top">
        <div class="message-conversions__top-text-block">
          <ar-text class="message-conversions__top-text" text="Revenue attributed to this message" />
          <ar-text
            v-if="nextUpdateStartDateFormatted"
            class="message-conversions__top-text-desc"
            size="xs"
            :text="`Data will update after ${nextUpdateStartDateFormatted}`"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
        </div>

        <div class="message-conversions__top-filters">
          <am2-select-search-events
            ref="select-search-events"
            :value="selectedEvents"
            :pre-selected-events="preSelectedEvents"
            :warning-message="warningMessage"
            @changeSelectedOids="handleChangeOids"
          />

          <SelectLinkDropdown
            v-if="currentSelectedMessageProvider === 'email'"
            ref="select-link"
            :value="selectedLinks"
            @changeSelectedOids="handleChangeSelectedLinks"
          />

          <ar-simple-select
            class="message-conversions__date-filter"
            :items="dateFilterItems"
            :default-select-index="dateFilterDefaultIndex"
            @select="handleSelectDate"
          />
        </div>


      </div>

    <template >


      <div class="message-conversions__cards">

        <conversion-stats-card
          :value="calculatedStatistic.revenue"
          :description=" isFetchingConversionStats ? 'Loading total conversion revenue ...' : 'Total conversion revenue' "
          :is-loading="isFetchingConversionStats || !isCalculationsFinished"
        />
        <conversion-stats-card
          :value="calculatedStatistic.totalOrders"
          :description=" isFetchingConversionStats ? 'Loading Quantity ...' : 'Quantity of orders' "
          :is-loading="isFetchingConversionStats || !isCalculationsFinished"
        />
        <conversion-stats-card
          :value="calculatedStatistic.avgTickets"
          :description=" isFetchingConversionStats ? 'Loading Tickets per recipient ...' : 'Avg. tickets per order' "
          :is-loading="isFetchingConversionStats || !isCalculationsFinished"
        />
      </div>

    </template>

      <no-content-section class="" style="margin-top: 40px;" v-if="!showConversionData && !selectedEvents.length "
        header="No conversion indicators are available."
        body="Link events and messages to help track your revenue and conversions"
      />


      <template v-if="!selectedEvents.length" >
        <no-content-section
          class="message-conversions__no-content"
          icon-name="ar-event-in-circle"
          header="Link your message to an event"
          body="Recipients who have purchased a ticket after receiving this message will display here"
          :button-text="'Add event'"
          @buttonClick="handleNoContentButtonClick"
        />
      </template>

      <template v-else>
        <div class="message-conversions__chart-wrapper">
          <conversions-chart />
        </div>

        <div class="message-conversions__table-section">
          <conversion-table />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import ConversionStatsCard from "~/pages/message-center/messages/view/components/conversion-stats-card.vue";
import ConversionTable from "~/pages/message-center/messages/view/components/conversion-table.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import NoContentSection from "~/components/sections/no-content-section/NoContentSection.vue";
import ConversionsChart from "~/pages/message-center/messages/view/components/conversions-chart.vue";
import SelectLinkDropdown from '~/components/dropdowns/select-link/SelectLinkDropdown.vue'

import { formatNumberWithCommas } from "~/utils/helpers";
import { formatStringToHoursAndTimezone } from "~/utils/date"

export default {
  name: 'MessageCenterMessageViewConversions',
  props: {
    refreshRecipients: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NoContentSection,
    ConversionTable,
    ConversionStatsCard,
    ConversionsChart,
    SelectLinkDropdown,
  },
  data() {
    return {
      dateFilterItems: [
        { name: 'First 24 hours', value: 1 },
        { name: 'First 7 days', value: 7 },
        { name: 'First 14 days', value: 14 },
      ],
      preSelectedEvents: [],
      isLinkFeatureEnabled: true,
      showConversionData: this.$store.state.message.conversionTableCount > 0
    }
  },
  computed: {
    ...mapState({
      selectedEvents: state => state.message.conversionSelectedEvents,
      oid: state => state.route.params.oid,
      isFetchingConversionStats: state => state.message.isFetchingConversionStats,
      conversionTableCount: state => state.message.conversionTableCount,
      isCalculationsFinished: state => state.message.isCalculationsFinished,
      promoterOid: (state) => state.auth.account.promoterOid,
      links: state => state.message.messageLinks,
      timeWindow: state => state.message.conversionTimeWindow,
      selectedLinks: state => state.message.conversionSelectedLinks,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      nextUpdateStartDate: state => state.message.nextUpdateStartDate,
      conversionStatsByTimeWindows: state => state.message.conversionStatsByTimeWindows,
    }),

    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),

    nextUpdateStartDateFormatted() {
      if (!this.nextUpdateStartDate) return null

      return formatStringToHoursAndTimezone(this.nextUpdateStartDate)
    },

    warningMessage() {
      return this.nextUpdateStartDateFormatted
        ? `Current data will be cleared, and updated data will appear after ${this.nextUpdateStartDateFormatted}`
        : null
    },


    currentSelectedMessageProvider() {
      return this.currentSelectedMessage?.provider;
    },

    calculatedStatistic() {
      let revenue = 0
      let totalOrders = 0
      let avgTickets = 0

      const conversionStats = this.conversionStatsByTimeWindows[this.timeWindow]

      if ( !conversionStats ) {
        return { revenue, totalOrders, avgTickets}
      }

      conversionStats.forEach((item) => {
        revenue += item?.indicators?.revenue || 0
        totalOrders += item?.indicators?.totalOrders || 0
        avgTickets += item?.indicators?.avgTickets || 0
      })

      revenue = `${this.regionSettingsCurrencySymbol}${formatNumberWithCommas(revenue / 100)}`
      totalOrders = formatNumberWithCommas(totalOrders)
      avgTickets = formatNumberWithCommas(conversionStats.length ? avgTickets / conversionStats.length : 0)

      return { revenue, totalOrders, avgTickets}
    },

    dateFilterDefaultIndex() {
      return this.dateFilterItems.findIndex(item => item.value === this.timeWindow)
    },
  },
  watch: {
    '$store.state.message.conversionTableCount'(newValue) {
      this.showConversionData = ( newValue > 0) ;
    },
    refreshRecipients(newVal) {
      if (newVal === true) {
        this.refreshConversion();
        this.$emit('refreshed-recipients');
      }
    },
  },
  methods: {
    ...mapMutations([
      'message/SET_CONVERSIONS_SELECTED_EVENTS',
      'message/SET_CONVERSIONS_TIME_WINDOW',
      'message/CLEAR_CONVERSIONS_TIME_WINDOW',
      'message/SET_CONVERSIONS_LINK'
    ]),
    ...mapActions([
      'message/CONVERSION_SET_ATTRIBUTION_PARAMS',
      'message/FETCH_CONVERSION_STATS_TIMESERIES',
      'message/FETCH_CONVERSION_RECIPIENTS',
      'message/FETCH_CONVERSION_STATISTICS',
      'auth/FETCH_PERMISSIONS',
    ]),
    /**
     * @function handleSelectDate
     * @description Handles date selection interaction
     */
    async handleSelectDate(item) {
      await this['message/SET_CONVERSIONS_TIME_WINDOW'](item.value);
    },
    async handleChangeOids(oids) {
      this['message/SET_CONVERSIONS_SELECTED_EVENTS'](oids);
      await this['message/CONVERSION_SET_ATTRIBUTION_PARAMS'](this.oid);
      this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid});
      this.fetchPreSelectedEvents()
    },
    handleNoContentButtonClick() {
      this.openSelectEventsDropdown()
    },
    openSelectEventsDropdown() {
      this.$refs['select-search-events'].$children[0].$children[0].$el.click()
    },
    async fetchPermissionsAndMaybeReroute() {
      await this['auth/FETCH_PERMISSIONS']();
      if (!this.isPermissionEnabled(['view-sales'])) {
        this.$router.push({
          path: `/message-center/messages/${this.oid}/view/overview`,
        });
      }
    },
    async handleChangeSelectedLinks(linksArray) {
      this['message/SET_CONVERSIONS_LINK'](linksArray);
      await this['message/CONVERSION_SET_ATTRIBUTION_PARAMS'](this.oid);
      this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid});
    },
    fetchPreSelectedEvents() {
      this.$axios.get(`/promoter/${this.promoterOid}/task/${this.oid}/attribution-selected-data`).then(data => {
        this.preSelectedEvents = data.data.events

        if (data.data.links) {
          this['message/SET_CONVERSIONS_LINK'](
            data.data.links
          )
        }

        if (data.data?.events?.length) {
          // set the selected events in case they missed in /attribution request
          this['message/SET_CONVERSIONS_SELECTED_EVENTS'](data.data.events.map(event => event.oid))
        }
      })
    },
    async refreshConversion() {
      this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid});
      this['message/FETCH_CONVERSION_RECIPIENTS'](this.oid)
      this['message/FETCH_CONVERSION_STATS_TIMESERIES'](this.oid)
    }
  },

  created() {
    if (!this.isFeatureEnabled(['conversions'])) {
      this.$router.push({
      path: '/audience',
    });
    }
  },

  mounted() {
    this.fetchPermissionsAndMaybeReroute();
    this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid, isSetFromSaved: true}).then(() => {
      if (this.selectedEvents.length) {
        this['message/FETCH_CONVERSION_STATS_TIMESERIES'](this.oid);
        this['message/FETCH_CONVERSION_RECIPIENTS'](this.oid);
      } else if (this.$route.query.method === 'open-select-events') {
        this.openSelectEventsDropdown()
      }
    });

    this.fetchPreSelectedEvents()
  },

  beforeDestroy() {
    this['message/SET_CONVERSIONS_SELECTED_EVENTS']([])
    this['message/CLEAR_CONVERSIONS_TIME_WINDOW']()
  },
}
</script>

<style lang="scss">
.message-conversions {
  display: flex;
  justify-content: center;

  &__content {
    max-width: 1200px;
    width: 100%;
    margin: 56px;

    @media (max-width: $xl-min) {
      margin: 0;
      padding: 56px;
    }
  }

  &__top {
    height: 48px;
    display: flex;
    align-items: center;

    @media (max-width: $lg-min) {
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      height: unset;
    }
   }

  &__top-text-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-right: auto;

  }

  &__top-filters {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__date-filter {
    @media (max-width: $sm-min) {
      flex-basis: 100%;
    }
  }

  &__chart-wrapper {
    margin: 32px 0;
  }

  &__cards {
    display: flex;
    gap: 24px;
    margin-top: 32px;

    @media (max-width: $lg-min) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__no-content {
    margin-top: 32px;
    border: 1px solid $blueGrey500;
    box-shadow: unset;
  }
}
</style>
