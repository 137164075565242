<template>
  <EditableFrame
    class="purchased-ticket-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    has-save-button
    :block-type="item.type"
    @close="$emit('close')"
    @save="handleSave"
  >
    <ar-text
      class="purchased-ticket-heading"
      size="14px"
      text="To event"
      weight="bold"
      multiple-lines
      :max-lines="1"
    />
    <div class="purchased-ticket-container">
      <am2-select-search-events
        style-like-select
        :default-label="selectedEventsLabel"
        @changeSelectedOids="handleSelectEventOids"
        :value="selectedEvents"
        enable-any-event
      />
      <div v-if="ticketTypesOpen" class="purchased-ticket-type-container">
        <ar-text
          class="purchased-ticket-type-title"
          size="14px"
          text="Ticket types"
          weight="bold"
          v-tooltip.top="{
            content: 'Unknown ticket types are generated based on ticket prices where ticket type did not exist in your data source.',
          }"
          multiple-lines
          :max-lines="1"
        />
        <ar-text
          class="purchased-ticket-type-subtitle"
          size="13px"
          text="If you do not select a ticket type all ticket types will be considered"
          multiple-lines
        />
        <div v-for="(ticketType, index) in ticketTypes" :key="ticketType.oid">
          <ar-checkbox
            class="purchased-ticket-type-checkbox"
            @input="(value) => handleCheckboxClick(ticketType.oid, value)"
            :value="isChecked(ticketType.oid)"
            :label="ticketType.name"
            :label-typography-props="{
              size: 'xs',
            }"
            :data-test-id="`automation-ticket-type-checkbox-${ticketType.oid}`"
          />
        </div>
      </div>
      <div v-if="!selectedEvents || selectedEvents.length === 0">
        <ar-text
          class="purchased-ticket-any-subtitle"
          size="13px"
          text="All ticket types will be considered, select an event if you want to target specific ticket types"
          multiple-lines
        />
      </div>
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions, mapState } from 'vuex'
import { displayDateRangeUSNoDay } from '@/utils/date/'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
export default {
  name: 'PurchasedTicket',
  components: {
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      ticketTypes: [],
      ticketTypeSelections: [],
      selectedEvents: []
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
  },
  computed: {
    ...mapState({
      events: state => state.event.eventsList,
    }),

    selectedEventsLabel() {
      if (!this.selectedEvents || this.selectedEvents.length === 0) {
        return 'Any event'
      } else if (this.selectedEvents.length === 1) {
        return '1 event selected'
      } else {
        return `${this.selectedEvents.length} events selected`
      }
    },

    ticketTypesOpen() {
      return this.ticketTypes.length > 0
    }
  },
  methods: {
    ...mapActions({
      fetchTicketClasses: 'automation/FETCH_TICKET_CLASSES',
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleCheckboxClick(oid, checked) {
      if (checked) {
        this.ticketTypeSelections.push(oid)
      } else {
        this.ticketTypeSelections = this.ticketTypeSelections.filter(item => item !== oid)
      }
    },
    isChecked(oid) {
      return  !!this.ticketTypeSelections.includes(oid)
    },
    async populateTicketTypes(eventOid) {
      const ticketClasses = await this.fetchTicketClasses({ eventOid })
      if (!!ticketClasses && !this.ticketTypes.includes(ticketClasses)) {
        this.ticketTypes = this.ticketTypes.concat(ticketClasses)
      }
    },
    handleSave() {
      let eventMetaData = {}
      let config = {}

      if (this.selectedEvents.length > 0) {
        const metaData = this.selectedEvents.map(selectedEvent => {
          const eventData = this.events.find((event) => {
            return event.oid === selectedEvent
          })

          return {
            name: eventData.name,
            location: eventData.location
          }
        })

        eventMetaData = {
          events: metaData
        }

        config = {
          'event-oids': this.selectedEvents,
          'should-match': 'at-least-one-of',
          'ticket-types': this.ticketTypeSelections,
        }
      }

      let contextData = {
        config: config,
        meta: eventMetaData
      }

      this.updateTriggerContextData({
        id: this.item.id,
        data: contextData
      })
      this.$emit('close')
    },
    resetDefaults() {
      this.selectedEvents = []
      this.ticketTypes = []
      this.ticketTypeSelections = []
    },
    async setItemVals() {
      this.resetDefaults()

      if (this.item.config?.['event-oids']) {
        this.ticketTypeSelections = this.item.config['ticket-types'] || []
        this.selectedEvents = this.item.config['event-oids']
        this.selectedEvents.forEach((eventOid) => {
          this.populateTicketTypes(eventOid)
        })
      } else if (this.item.config?.['event-oid']) {
        // Normalise old event-oid data put into event-oids
        this.ticketTypeSelections = this.item.config['ticket-types'] || []
        this.selectedEvents = [parseInt(this.item.config['event-oid'])]
        this.populateTicketTypes(this.item.config['event-oid'])
      } else {
        this.selectedEvents = []
      }
    },
    handleSelectEventOids(oids) {
      this.resetDefaults()
      this.selectedEvents = oids
      this.selectedEvents.forEach((eventOid) => {
        this.populateTicketTypes(eventOid)
      })
    }
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.purchased-ticket-wrapper {
  width: 100%;
  height: 100%;

  .purchased-ticket-heading {
    margin-bottom: 14px;
    pointer-events: none;
  }

  .purchased-ticket-container {
    width: 100%;

    .purchased-ticket-search-wrapper {
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-bottom: 10px;

      .purchased-ticket-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .purchased-ticket-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 360px;
        overflow: auto;
        padding-bottom: 10px;

        .event-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          &.no-event-found-wrapper:hover {
            background: unset;
            cursor: default;
          }

          .item-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            width: 100%;
            padding: 9px;

            .item-name {
              &.selected {
                color: $purple500;
              }
            }

            .purchased-ticket-date-string, .purchased-ticket-location {
              color: #c1c6cf;

              &.selected {
                color: #ad8adf;
              }
            }
          }
        }
      }
    }
  }

  .purchased-ticket-type-container {
    .purchased-ticket-type-title {
      font-weight: 500;
      margin-top: 32px;
    }
    .purchased-ticket-type-subtitle {

      margin-top: 12px;
      color: $blueGrey700;
    }
    .purchased-ticket-type-checkbox {
      margin-top: 16px;
    }
  }


  .purchased-ticket-any-subtitle {

    margin-top: 12px;
    color: $blueGrey700;
  }
}

.purchased-ticket-options-wrapper {
  width: 100%;

  .option-heading {
    margin-bottom: 14px;
  }

  .options-heading {
    margin-top: 52px;
    margin-bottom: 14px;
  }
}
</style>
