/**
 * @function isValidUrl
 * @description Accepts a URL as a string, determines if it is a valid URL, a valid email address, a valid mailto link, or a valid tel link.
 * @param urlString - The string to validate as a URL, email, mailto link, or tel link.
 * @returns boolean - Returns true if valid URL, email, mailto link, or tel link; false otherwise.
 */
export const isValidUrl = (urlString: string): boolean => {
  // Pattern for matching tel: links
  
  // console.log( urlString );

  const telPattern = /^tel:\+?[0-9\s()-]+$/i;


  try {
    // Directly check for tel: links before any other processing
    if (urlString.startsWith("tel:")) {
      return telPattern.test(urlString);
    }

    // Encode whitespace for URLs (not for tel links)
    const encodedUrl = urlString.replace(/\s/g, '%20');

    const emailPattern = new RegExp(
      "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+" +
      "(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*" +
      "@" +
      "(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+" +
      "[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$",
      "i"
    );

    const urlPattern = new RegExp(
      "^(https?:\\/\\/)" +
      "(?:[\\S]+:[\\S]+@)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+–—]*)*" +
      "(\\?[;&a-z\\d%_.~+=\\-]*)*" +
      "(\\#[-a-z\\d_]*)?$",
      "i"
    );


    // 📝 Presently disabled: we've been receiving too many issues with this pattern, 
    // when mailto is handled by beefree populating subj/body fields already.

      // Pattern for matching mailto links
      // Example: "mailto:info@audiencearepublic.com?subject=Testing"
      // Captures the email address and optional query string
      // const mailtoPattern = `/^mailto:.*@.*(\?.*)?$/i`;

      // Check for mailto links specifically
    if (urlString.startsWith("mailto:")) {
      return true 
      // const match = urlString.match(mailtoPattern);
      // return !!match && emailPattern.test(match[1]);
    }

    // Validate as URL
    if (encodedUrl.includes("://") || encodedUrl.match(urlPattern)) {
      return urlPattern.test(encodedUrl);
    }

    // Validate plain email format
    return emailPattern.test(encodedUrl);
  } catch (e) {
    return false;
  }
};
