<template>
  <EditableFrame
    class="birthday-trigger-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    @close="$emit('close')"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    :block-type="item.type"
    @save="handleSave"
    @click.native="handleClickOutside"
  >
    <ar-text
      class="birthday-trigger-heading"
      size="13px"
      text="BIRTHDAY TRIGGER"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="birthday-trigger-copy-wrapper">
      <ar-text
      class="birthday-trigger-copy"
      size="13px"
      text="When added to an automation, steps below this trigger will begin on the day of a fan's birthday."
      weight="normal"
      multiple-lines
      letter-spacing="1px"
      />
      <ar-field label="When will this trigger activate?" class="u-margin-top-8">
        <ar-select
          class="select-when-wrapper"
          :items="selectableWhenOptions"
          :default-select-index="selectedWhenIndex ? selectedWhenIndex : 0"
          :hide-arrow="false"
          :dropdown-width="300"
          @select="handleWhenSelect"
          @actionClick="handleWhenAction"
        >
          <template slot="selected-item" slot-scope="selectedItemScope">
            <div
              :class="[
                'u-display-flex',
                'u-align-items-center',
                'u-padding-x-4',
              ]"
            >
              <ar-text
                :class="[
                  'u-margin-left-2',
                ]"
                :text="selectedItemScope.item.name"
                size="xs"
              />
            </div>
          </template>
          <template slot="item" slot-scope="itemScope">
            <div
              v-if="itemScope.item.isAction"
              :class="[
                'u-display-flex',
                'u-align-items-center',
                'u-padding-x-4',
              ]"
              :style="{
                cursor: 'pointer',
                height: '50px',
                background: itemScope.active ? $arStyle.color.purple100 : null,
              }"
            >
              <ar-text
                :class="[
                  'u-margin-left-2',
                ]"
                :text="itemScope.item.name"
                size="xs"
                :style="{
                  lineHeight: 1,
                }"
              />
            </div>
          </template>
        </ar-select>
      </ar-field>
      <ar-field
        v-if="showTimeSelector"
        :label="selectTimeLabel"
        class="u-margin-top-8">
        <ar-select
          class="select-time-wrapper"
          :items="selectableTimeOptions[selectableWhenOptions[selectedWhenIndex].key]"
          :default-select-index="selectedTimeIndex ? selectedTimeIndex : 0"
          :hide-arrow="false"
          :dropdown-width="300"
          @select="handleTimeSelect"
          @actionClick="handleTimeAction"
        >
          <template slot="selected-item" slot-scope="selectedItemScope">
            <div
              :class="[
                'u-display-flex',
                'u-align-items-center',
                'u-padding-x-4',
              ]"
            >
              <ar-text
                :class="[
                  'u-margin-left-2',
                ]"
                :text="selectedItemScope.item.name"
                size="xs"
              />
            </div>
          </template>
          <template slot="item" slot-scope="itemScope">
            <div
              v-if="itemScope.item.isAction"
              :class="[
                'u-display-flex',
                'u-align-items-center',
                'u-padding-x-4',
              ]"
              :style="{
                cursor: 'pointer',
                height: '50px',
                background: itemScope.active ? $arStyle.color.purple100 : null,
              }"
            >
              <ar-text
                :class="[
                  'u-margin-left-2',
                ]"
                :text="itemScope.item.name"
                size="xs"
                :style="{
                  lineHeight: 1,
                }"
              />
            </div>
          </template>
        </ar-select>
      </ar-field>
    </div>
    </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Birthday',
  components: {
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectableWhenOptions: [
        {
          isAction: true,
          name: 'Before birthday',
          key: 'BEFORE_BIRTHDAY'
        },
        {
          isAction: true,
          name: 'Day of birthday',
          key: 'DAY_OF_BIRTHDAY'
        },
        {
          isAction: true,
          name: 'After birthday',
          key: 'AFTER_BIRTHDAY'
        }
      ],
      selectableTimeOptions: {
        "BEFORE_BIRTHDAY": [
          {
            isAction: true,
            name: '1 day before',
            key: '1_DAY_BEFORE'
          },
          {
            isAction: true,
            name: '2 days before',
            key: '2_DAYS_BEFORE'
          },
          {
            isAction: true,
            name: '3 days before',
            key: '3_DAYS_BEFORE'
          },
          {
            isAction: true,
            name: '4 days before',
            key: '4_DAYS_BEFORE'
          },
          {
            isAction: true,
            name: '5 days before',
            key: '5_DAYS_BEFORE'
          },
          {
            isAction: true,
            name: '6 days before',
            key: '6_DAYS_BEFORE'
          },
          {
            isAction: true,
            name: '1 week before',
            key: '1_WEEK_BEFORE'
          },
          {
            isAction: true,
            name: '2 weeks before',
            key: '2_WEEKS_BEFORE'
          },
          {
            isAction: true,
            name: '1 month before',
            key: '1_MONTH_BEFORE'
          }
        ],
        "AFTER_BIRTHDAY": [
          {
            isAction: true,
            name: '1 day after',
            key: '1_DAY_AFTER'
          },
          {
            isAction: true,
            name: '2 days after',
            key: '2_DAYS_AFTER'
          },
          {
            isAction: true,
            name: '3 days after',
            key: '3_DAYS_AFTER'
          },
          {
            isAction: true,
            name: '4 days after',
            key: '4_DAYS_AFTER'
          },
          {
            isAction: true,
            name: '5 days after',
            key: '5_DAYS_AFTER'
          },
          {
            isAction: true,
            name: '6 days after',
            key: '6_DAYS_AFTER'
          },
          {
            isAction: true,
            name: '1 week after',
            key: '1_WEEK_AFTER'
          },
          {
            isAction: true,
            name: '2 weeks after',
            key: '2_WEEKS_AFTER'
          },
          {
            isAction: true,
            name: '1 month after',
            key: '1_MONTH_AFTER'
          }
        ]
      },
      selectedWhenIndex: 0,
      selectedTimeIndex: 0
    }
  },
  watch: {
    'item.config' () {
      this.setItemVals();
    }
  },
  mounted() {
    this.setItemVals();
  },
  methods: {
    ...mapActions({
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleWhenAction(action) {
      this.selectedWhenIndex = this.selectableWhenOptions.map(e => e.key).indexOf(action.key);
      this.selectedTimeIndex = 0;
    },
    handleWhenSelect(v, idx) {
      this.handleWhenAction(v);
    },
    handleTimeAction(action) {
      this.selectedTimeIndex = this.selectableTimeOptions[this.selectableWhenOptions[this.selectedWhenIndex].key].map(e => e.key).indexOf(action.key);
    },
    handleTimeSelect(v, idx) {
      this.handleAction(v);
    },
    handleSave() {
      const whenKey = this.selectableWhenOptions[this.selectedWhenIndex].key;
      let contextData = {
        config: {
          when: whenKey,
          time: whenKey !== 'DAY_OF_BIRTHDAY' ? this.selectableTimeOptions[whenKey][this.selectedTimeIndex].key : null
        }
      };
      this.updateTriggerContextData({
        id: this.item.id,
        data: contextData
      });
      this.$emit('close');
    },
    handleClickOutside(e) {
    },
    resetDefaults() {
      this.selectedWhenIndex = 0;
      this.selectedTimeIndex = 0;
    },
    async setItemVals() {
      this.resetDefaults();
      const itemConfig = this.item.config;
      if (itemConfig?.when !== undefined && itemConfig?.when !== null) {
        this.selectedWhenIndex = this.selectableWhenOptions.map(e => e.key).indexOf(itemConfig.when);
      }
      if (itemConfig?.time !== undefined && itemConfig?.time !== null) {
        this.selectedTimeIndex = this.selectableTimeOptions[this.selectableWhenOptions[this.selectedWhenIndex].key].map(e => e.key).indexOf(itemConfig.time);
      }
    }
  },
  computed: {
    savedButtonDisabled() {
      return this.selectedWhenIndex === null || this.selectedTimeIndex === null;
    },
    showTimeSelector() {
      return this.selectedWhenIndex !== null && this.selectedWhenIndex !== -1 && this.selectableWhenOptions[this.selectedWhenIndex].key !== 'DAY_OF_BIRTHDAY';
    },
    selectTimeLabel() {
      if (this.selectedWhenIndex !== null) {
        const whenKey = this.selectableWhenOptions[this.selectedWhenIndex].key;
        if (whenKey !== 'DAY_OF_BIRTHDAY') {
          return 'How many days '.concat((whenKey === 'BEFORE_BIRTHDAY') ? 'before' : 'after').concat('?');
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.birthday-trigger-wrapper {
  width: 100%;
  height: 100%;

  .birthday-trigger-copy-wrapper {
    .birthday-trigger-copy {
      margin-top: 14px;
    }
  }
}
</style>
