<template>
  <div class="add-dynamic-tags-section">
    <!-- Step 1, upload CSV -->
    <div v-if="step === 1" class="body">
      <div
        :class="[
          'upload-csv-body',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div v-show="!isParsingCsvFile">
          <ar-text
            size="xs"
            text="Dynamic tags allow you to personalize your messages (e.g. unique codes, custom urls, etc)."
            multiple-lines
          />
          <am2-heading
            size="xs"
            title="Step 1"
            weight="bold"
            :style="{
              marginTop: '28px',
              marginBottom: '4px',
            }"
          />
          <div
            class="step-1-desc-section"
            :style="{
              marginTop: '2px',
            }"
          >
            <ar-icon
              name="download"
              :color="$arStyle.color.purple500"
            />
            <ar-text
              size="xs"
              text="<a>Download this csv file</a> containing all recipients of this message."
              multiple-lines
              line-height="25px"
              allow-html
              :style="{
                marginLeft: '4px',
              }"
              @anchorClick="handleStepOneDescriptionAnchorClick"
            />
          </div>
          <am2-heading
            size="xs"
            title="Step 2"
            weight="bold"
            :style="{
              marginTop: '28px',
              marginBottom: '4px',
            }"
          />
          <ar-text
            size="xs"
            text="Add a new column for your dynamic tag and specify the info for each contact (the column header will be the name of your dynamic tag)."
            multiple-lines
            line-height="25px"
            allow-html
          />
          <am2-heading
            size="xs"
            title="Step 3"
            weight="bold"
            :style="{
              marginTop: '28px',
              marginBottom: '4px',
            }"
          />
          <ar-text
            size="xs"
            text="Upload the csv file below. Once uploaded you'll be able to insert tags into your message from the dynamic tags menu."
            multiple-lines
            line-height="25px"
            allow-html
          />
        </div>
        <div class="dropzone-section">

        <am2-dropzone
          ref="dropzone-wrapper"
          class="u-margin-top-5"
          :file-type="['text/csv']"
          :file-size="100000000"
          :csv-options="{
            shortUrl: true,
          }"
          file-type-alias="CSV file"
          :placeholder-icon="{
            name: 'upload',
            width: '16px',
            height: '16px',
            color: $arStyle.color.purple500,
            wrapperStyle: {
              background: mouseOverCsvDropdown ? `white` : $arStyle.color.skyBlueGrey400,
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: mouseOverCsvDropdown ? '2px solid white' : null,
            },
          }"
          :ratio="0.525"
          @uploadStart="isParsingCsvFile = true"
          @uploadEnd="isParsingCsvFile = false"
          @upload="handleCSVUploaded"
          @mouseover.native="mouseOverCsvDropdown = true"
          @mouseleave.native="mouseOverCsvDropdown = false"
          data-test-id="upload-dynamic-tags-dropzone"
        />
        </div>
      </div>
    </div>
    <!-- Step 3, error message, if no, skip it -->
    <div v-else-if="step === 2" class="body">
      <div
        class="error-message-body"
      >
        <ar-icon
          name="alert-exclamation-mark"
          height="70px"
        />
        <am2-heading
          size="md"
          :title="errorMessageTitle"
          align="center"
          :style="{
            marginTop: '22px',
          }"
        />
        <ar-text
          size="xs"
          :text="errorMessage"
          multiple-lines
          line-height="25px"
          align="center"
          :style="{
            marginTop: '14px',
          }"
        />
      </div>
    </div>
    <!-- Import complete -->
    <div v-else-if="step === 3" class="body">
      <div
        class="csv-review-body"
      >
        <ar-text
          size="xs"
          text="Verify that your dynamic fields are correct with the preview below."
          multiple-lines
          line-height="25px"
        />
        <am2-csv-review-table
          :head="csvHeaders"
          :body="csvRows"
          :style="{
            marginTop: '24px',
          }"
        />
      </div>
    </div>

    <!-- FOOTER -->
    <div
      v-if="step === 1"
      :class="[
        'footer',
        'footer-for-step-one',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
      ]">
      <ar-text
        size="xs"
        text="Tip: You can add more dynamic fields by adding more columns."
        multiple-lines
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
      />
    </div>
    <div
      v-if="step === 3"
      slot="footer"
      class="footer-for-step-four"
    >
      <ar-simple-button
        text="Import Fields"
        :loading="isImportingCsv"
        @click="handleImportFieldsClick"
        data-test-id="import-fields-button"
      />
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { convertToSnakeCase } from '@/utils/helpers';

export default {
  name: 'AddDynamicTagsSection',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    messageListOid: {
      type: Number,
      default: null,
    },
    channel: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      step: 1,
      fansCountInCsvFile: null,
      errorMessageTitle: null,
      errorMessage: null,
      csvHeaders: [],
      csvRows: [],
      isParsingCsvFile: false,
      isImportingCsv: false,
      mouseOverCsvDropdown: false,
      tagMaxWidthMap: {},
    };
  },
  computed: {
    ...mapState({
      isExportingListSubscribers: state => state.subscriber.isExportingListSubscribers,
    }),
    ...mapGetters({
      getCurrentFilterExpression: 'message/getCurrentFilterExpression',
    }),
    modalTitle() {
      return 'Add dynamic tags';
    },
  },
  watch: {
    isShow(newIsShow) {
      if (newIsShow) {
        this.step = 1;
        this.fansCountInCsvFile = null;
        this.tagMaxWidthMap = {};
        this.$refs?.['dropzone-wrapper']?.calculateDropzoneHeight()
      }
    },
  },

  methods: {
    ...mapActions([
      'subscriber/EXPORT_LIST_SUBSCRIBERS_CSV',
      'UPLOAD_ASSET',
    ]),
    async handleImportFieldsClick() {
      try {
        const csvFile = this.$arUtils.csv.convertJsonToCsvFile([
          this.csvHeaders,
          ...this.csvRows,
        ], null);
        this.isImportingCsv = true;
        const data = await this.UPLOAD_ASSET({
          assetType: 'message-dynamic-tags',
          contentType: 'text/csv',
          file: csvFile,
        });

        this.csvResource = data;

        this.$arNotification.push({
          type: 'success',
          message: 'Successfully added dynamic tags',
        });

        this.$emit('import', {
          dynamicTagHeaders: this.csvHeaders,
          dynamicTagRows: this.csvRows,
          tagsResourceOid: this.csvResource.oid,
          tagMaxWidthMap: this.tagMaxWidthMap,
        });
      } catch (e) {
        console.error(e);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to import dynamic tags',
        });
      } finally {
        this.isImportingCsv = false;
      }
    },
    handleClose() {
      this.$emit('cancel');
    },
    async handleDownloadCsvClick() {
      // so we don't request more than one CSV at a time
      if (this.isExportingListSubscribers) {
        return;
      }

      const select = ['firstName', 'lastName'];
      if (this.channel === 'email' || this.channel === 'facebookMessenger') {
        select.push('emailAddress');
      } else if (this.channel === 'sms') {
        select.push('mobileNumber');
      }

      this['subscriber/EXPORT_LIST_SUBSCRIBERS_CSV']({
        select: select.join(','),
        messageListOid: this.messageListOid,
        channels: this.channel,
        csvHeaderCase: 'snake',
        filter: this.getCurrentFilterExpression,
      });
    },
    async handleCSVUploaded({
      additionalInfo: { headers, body, columnsWithEmptyCells, maxColumnWidthMap },
    }) {
      // Make all headers snake case
      const csvHeaders = headers.map(header => {
        return convertToSnakeCase(header);
      });

      if ((this.channel === 'email' || this.channel === 'facebookMessenger' ) && csvHeaders.indexOf('email_address') === -1) {
        this.errorMessageTitle = 'Email address column missing';
        this.errorMessage = 'We did not detect the email address column in your CSV file. Please ensure you use the template provided.';
        this.step = 2;
        return;
      }

      if (this.channel === 'sms' && csvHeaders.indexOf('mobile_number') === -1) {
        this.errorMessageTitle = 'Mobile number column missing';
        this.errorMessage = 'We did not detect the mobile number column in your CSV file. Please ensure you use the template provided.';
        this.step = 2;
        return;
      }

      if (this.channel === 'email' && columnsWithEmptyCells['email_address']) {
        this.errorMessageTitle = 'Email address column must be complete';
        this.errorMessage = 'Your email address column is incomplete. Go back and make sure you upload a file which has complete email address column.';
        this.step = 2;
        return;
      }

      if (this.channel === 'sms' && columnsWithEmptyCells['mobile_number']) {
        this.errorMessageTitle = 'Mobile number column must be complete';
        this.errorMessage = 'Your mobile number column is incomplete. Go back and make sure you upload a file which has complete mobile number column.';
        this.step = 2;
        return;
      }

      this.tagMaxWidthMap = maxColumnWidthMap;

      this.step = 3;
      this.csvHeaders = csvHeaders;
      this.csvRows = body;
    },
    handleStepOneDescriptionAnchorClick(idx) {
      if (idx === 0) { // Download CSV clicked
        this.handleDownloadCsvClick();
      }
    },
    handleBackClick() {
      if (this.step === 3) {
        this.step -= 2;
      } else {
        this.step -= 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-dynamic-tags-section {
  background: #f3f6fa;
  padding: 30px 40px 50px;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .body {
    max-width: 100%;
  }

  .dropzone-section {

    display: block;
  }

  .upload-csv-body {
    padding: 25px 30px;
    .step-1-desc-section {
      display: flex;
      align-items: center;
    }
  }
  .error-message-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 91px 75px 179px;
  }
  .csv-review-body {
    padding: 29px 26px;
  }
  .footer {
    width: 100%;
  }
  .footer-for-step-one {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $skyBlueGrey300;
    height: 77px;
  }
  .footer-for-step-four {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    padding: 0 30px;
  }
}
</style>
