var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"ask-ticketek-access-token-modal",attrs:{"is-open":_vm.isShow,"header":"Connect To Ticketek","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'ask-ticketek-access-token-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"u-display-flex u-margin-top-8 u-margin-bottom-3"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":"Subscribe associated contacts to list","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: "Automatically subscribe all opted-in attendees of events linked to this Ticketek bucket into a messaging list. Opted-in attendees will be capable of receiving emails.",
          }),expression:"{\n            content: `Automatically subscribe all opted-in attendees of events linked to this Ticketek bucket into a messaging list. Opted-in attendees will be capable of receiving emails.`,\n          }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        }),attrs:{"error-messages":[
          _vm.veeErrors.first('messageList')
        ]}},[_c('am2-message-list-select',{attrs:{"message-list-type":"manual","show-stats":false,"channels":['email']},model:{value:(_vm.messageList),callback:function ($$v) {_vm.messageList=$$v},expression:"messageList"}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '20px',
        }),attrs:{"label":"S3 Bucket Name","error-messages":[
          _vm.veeErrors.first('s3BucketName')
        ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-test-id":"ticketek-modal-s3-bucket-name","data-vv-name":"s3BucketName","data-vv-as":"S3 Bucket Name","autocomplete":"ticketek-s3-bucket-name","placeholder":"Please enter s3 bucket name"},model:{value:(_vm.s3BucketName),callback:function ($$v) {_vm.s3BucketName=$$v},expression:"s3BucketName"}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '20px',
        }),attrs:{"label":"S3 Bucket Region","error-messages":[
          _vm.veeErrors.first('s3BucketRegion')
        ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|isValidRegion'),expression:"'required|isValidRegion'"}],attrs:{"data-test-id":"ticketek-modal-s3-bucket-region","data-vv-name":"s3BucketRegion","data-vv-as":"S3 Bucket Region","autocomplete":"ticketek-s3-bucket-region","placeholder":"Please enter s3 bucket region"},model:{value:(_vm.s3BucketRegion),callback:function ($$v) {_vm.s3BucketRegion=$$v},expression:"s3BucketRegion"}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '20px',
        }),attrs:{"label":"S3 Bucket Access Key","error-messages":[
          _vm.veeErrors.first('s3BucketAccessKey')
        ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-test-id":"ticketek-modal-s3-bucket-access-key","data-vv-name":"s3BucketAccessKey","data-vv-as":"S3 Bucket Access Key","autocomplete":"ticketek-s3-bucket-acess-key","placeholder":"Please enter s3 bucket access key"},model:{value:(_vm.s3BucketAccessKey),callback:function ($$v) {_vm.s3BucketAccessKey=$$v},expression:"s3BucketAccessKey"}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '20px',
        }),attrs:{"label":"S3 Bucket Secret Key","error-messages":[
          _vm.veeErrors.first('s3BucketSecretKey')
        ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-test-id":"ticketek-modal-s3-bucket-secret-key","data-vv-name":"s3BucketSecretKey","data-vv-as":"S3 Bucket Secret Key","autocomplete":"ticketek-s3-bucket-secret-key","type":"password","placeholder":"Please enter s3 bucket secret key"},model:{value:(_vm.s3BucketSecretKey),callback:function ($$v) {_vm.s3BucketSecretKey=$$v},expression:"s3BucketSecretKey"}})],1),_vm._v(" "),_c('ar-link-button',{staticClass:"u-margin-top-6",attrs:{"data-test-id":"ticketek-modal-setup-support-link","text":"How to get your Ticketek credentials","has-underline":true},on:{"click":_vm.handleSetupClick}}),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        })},[_c('ar-checkbox',{key:"tag-fans",staticClass:"u-margin-right-5",attrs:{"label":"Assign tags from the &ldquo;product-marketing-type&rdquo; field<br>to newly created fan accounts.","data-test-id":"checkbox-ticketek-assign-tags"},model:{value:(_vm.tagFans),callback:function ($$v) {_vm.tagFans=$$v},expression:"tagFans"}})],1)],1),_vm._v(" "),_c('div',{class:[
        'ask-ticketek-access-token-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"data-test-id":"ticketek-modal-confirm","text":"Confirm","loading":_vm.loading,"disabled":_vm.confirmButtonDisabled},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }