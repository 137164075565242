<template>
  <div>
    <SelectSearchDropdown
      :default-label="defaultLabel"
      :selected-ids="selectedIdsObject"
      :options="showedEvents"
      :is-loading="isFetchingEvents && !isLastEventLoaded"
      :is-empty="value.length === 0"
      :style-like-select="styleLikeSelect"
      :enable-any="enableAnyEvent"
      :warning-message="warningMessage"
      any-option-text="Any event"
      @search="handleSearch"
      @finish="handleClickFinish"
      @clickSelect="handleClickSelect"
      @clear="handleClickClear"
      @close="handleClose"
      @open="handleOpen"
      @load-more="handleLoadMore"
    >
      <div class="select-search-events-option" slot="item" slot-scope="{ option }">
        <div class="select-search-events-option__wrapper">
          <ar-text
            class="select-search-events-option__event-name"
            size="xs"
            :text="option.name || ''"
            multiple-lines
            :max-lines="1"
            line-height="20px"
            weight="bold"
          />
          <ar-text
            v-if="option.dateString"
            class="select-search-events-option__date-string"
            size="xs"
            :text="option.dateString"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
          <ar-text
            v-if="option.location"
            class="select-search-events-option__date-string"
            size="xs"
            :text="option.location"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
        </div>
      </div>
    </SelectSearchDropdown>
  </div>
</template>

<script>
import SelectSearchDropdown from './SelectSearchDropdown.vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {displayDateRangeUSNoDay} from "~/utils/date";
import dayjs from "dayjs";
export default {
  name: 'SelectSearchEventsDropdown',
  components: {
    SelectSearchDropdown,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    styleLikeSelect: {
      type: Boolean,
      default: false,
    },
    defaultLabel: {
      type: String,
      default: 'Select Event',
    },
    // This variable is needed to display certain events at the top of the list
    // in case the user has selected events previously
    preSelectedEvents: {
      type: Array,
      default: () => [],
    },
    enableAnyEvent: {
      type: Boolean,
      default: false,
    },
    warningMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      eventOptions: [],
      selectedOid: {},
      searchText: '',
    }
  },
  watch: {
    value: {
      handler: function () {
        this.restoreSelectedIdsFromValue()
      },
    }
  },
  mounted() {
    this['event/RESET_ALL_EVENTS_LIST']()
    this.fetchEventsOptions();
    this.restoreSelectedIdsFromValue()
  },

  computed: {
    ...mapState({
      isLastEventLoaded: state => state.event.isLastEventListLoaded,
      selectedEvents: state => state.event.selectedEventsList,
      isFetchingEvents: state => state.event.isFetchingEventsList,
      events: state => state.event.eventsList,
      eventsListSearch: state => state.event.eventsListSearch,
    }),
    ...mapGetters({
      selectedIdsObject: 'event/getSelectedEventListIdsObject',
    }),

    showedEvents() {
      if (this.preSelectedEvents.length === 0 || this.eventsListSearch.length !== 0) {
        return this.getStructuredOption(this.events)
      }
      const eventsWithoutSelected = this.events.filter(item => !this.value.includes(item.oid));
      return this.getStructuredOption([...this.preSelectedEvents, ...eventsWithoutSelected])
    },

    placeholder() {
      if (this.value.length === 1) return '1 event'
      return this.value.length ? `${this.value.length} events` : 'Select events...'
    },
  },

  methods: {
    ...mapActions({
      fetchEvents: 'event/FETCH_EVENTS_LIST',
    }),

    ...mapMutations([
      'event/SELECT_EVENT_LIST',
      'event/SET_SELECTED_EVENTS_LIST',
      'event/CLEAR_SELECTED_EVENTS_LIST',
      'event/RESET_ALL_EVENTS_LIST',
      'event/RESET_EVENTS_LIST',
      'event/SET_EVENTS_LIST_SEARCH',
    ]),

    async handleSearch(text) {
      this['event/RESET_EVENTS_LIST']()
      this['event/SET_EVENTS_LIST_SEARCH'](text)

      await this.fetchEventsOptions()
    },

    async fetchEventsOptions() {
      await this.fetchEvents({ selectString: 'name,startDate,location', orderBy: 'datecreated' })
    },

    handleLoadMore() {
      this.fetchEventsOptions()
    },

    getStructuredOption(rows) {
      return rows.map(item => {
        return {
          oid: item.oid,
          name: item.name,
          location: item.location,
          dateString: displayDateRangeUSNoDay(dayjs(item.startDate).valueOf(), null, dayjs.tz.guess()),
        }
      })
    },

    handleClickFinish() {
      const oidArray = this.selectedEvents.map((event) => event.oid)
      this.$emit('changeSelectedOids', oidArray)
    },

    handleClickSelect({ key, newValue }) {
      if (key === 'any') {
        this['event/CLEAR_SELECTED_EVENTS_LIST']()
      } else {
        this['event/SELECT_EVENT_LIST']({ oid: key, value: newValue })
      }
    },

    handleClickClear() {
      this['event/CLEAR_SELECTED_EVENTS_LIST']()
    },

    handleClose() {
      this.restoreSelectedIdsFromValue()
    },

    restoreSelectedIdsFromValue() {
      this['event/CLEAR_SELECTED_EVENTS_LIST']()
      const selectedEvents = this.value.map((oid) => ({ oid }))
      this['event/SET_SELECTED_EVENTS_LIST'](selectedEvents)
    },

    handleOpen() {
      this.$emit('open')
    }
  },
}
</script>

<style lang="scss">
.select-search-events-option {
  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    cursor: default;
  }

  &__date-string {
    color: #c1c6cf;
  }
}

.select-search-dropdown__option-wrapper {
  max-height: 230px !important;
}
</style>
