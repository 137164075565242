<template>
  <div
    :id="item.oid"
    :ref="`autoCard${item.oid}`"
    class="automation-list-item-wrapper"
    @click="handleCardClick"
    :data-test-id="`automation-card-${automationNameInKebabCase}`"
  >
    <div class="automation-card-left-wrapper">
      <ar-text
        class="automation-name-heading"
        size="16px"
        :text="item.name || 'Untitled automation'"
        weight="bold"
        multiple-lines
        :max-lines="1"
        line-height="25px"
      />
      <!--
      Temporarily commenting out.
      Will be part of version 2 and
      hooked up to live stats from
      the backend

      <div class="stats-wrapper">
        <AutomationUsers
          :users="dummyUsers"
        />
        <AutomationStatsPreview
          :stats="statsPreview"
        />
      </div>
      -->
    </div>
    <div class="automation-card-right-wrapper">
      <div v-if="backendIsProcessing" class="loading-bubble-wrapper">
        <am2-loading-bubble />
      </div>
      <template v-else>
        <am2-signal-tag
          :data-test-id="`automation-list-${signalTagText}`"
          v-if="item.status !== 'new' && !!signalTagText.length"
          class="signal-tag"
          :text="signalTagText"
          :style="{}"
          :type="signalTagType"
          :hide-dot="shouldHideDot"
        />
      </template>
      <am2-icon-button-dropdown
        :key="item.oid"
        :hovered-color="$arStyle.color.blueGrey700"
        :items="menuActions"
        :divider-style="{margin: '5px 0'}"
        @select="handleMenuOptionClick(item.oid, $event)"
      />
    </div>
  </div>
</template>
<script>
import AutomationUsers from '@/pages/automation/create/components/AutomationHeader/AutomationUsers.vue'
import AutomationStatsPreview from '@/pages/automation/create/components/AutomationHeader/AutomationStatsPreview.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { sanitizeHtmlChild } from '@/utils/html-element/';

export default {
  name: 'AutomationCard',
  components: {
    AutomationUsers,
    AutomationStatsPreview,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      menuTop: false,
      dummyUsers: [
        { src: "https://images.pexels.com/photos/3383195/pexels-photo-3383195.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/2833348/pexels-photo-2833348.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
      ],
      statsPreview: {
        completions: 130,
        inProgress: 12,
      },
      localStatus: null,
      poller: null,
      pendingStates: ['should-start', 'starting', 'should-pause', 'pausing', 'should-stop', 'stopping', 'recover-from-error'],
      menuActions: [
        {
          name: 'Start',
          action: this.startAutomation,
          key: 'started',
          disabled: false,
        },
        {
          name: 'Pause',
          action: this.pauseAutomation,
          key: 'paused',
          disabled: false,
        },
        {
          name: 'Stop',
          action: this.stopAutomation,
          key: 'stopped',
          disabled: false,
        },
        { type: 'divider', disabled: true },
        {
          name: 'Duplicate',
          action: this.duplicateAutomation,
          disabled: false,
        },
        { type: 'divider', disabled: true },
        {
          name: 'Delete',
          action: this.deleteAutomationModal,
          disabled: false,
        }
      ]
    }
  },
  watch: {
    'item.status': {
      immediate: true,
      handler() {
        this.localStatus = this.item.status
      }
    },
    localStatus() {
      if (this.localStatus === 'error') {
        this.$arNotification.push({ type: 'error', message: `An error occured when updating the status of Automation '${this.item.name}'.` })
        this.resetMenuOptions()
      }
    },
    isPendingStatus: {
      immediate: true,
      handler(_, oldVal) {
        if (this.isPendingStatus) {
          if (!this.poller) {
            this.poller = setInterval(this.checkStatus, 2000)
          }
        } else {
          clearInterval(this.poller)
          this.poller = null
          if (oldVal) {
            this.$emit('filter', {item: this.item, status: this.localStatus})
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      automations: state => state.automation.automationsList
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    isPendingStatus() {
      return !!this.localStatus && !!(this.localStatus.includes('should') || this.localStatus.includes('ing') || this.localStatus.includes('recover'))
    },
    backendIsProcessing() {
      return this.pendingStates.includes(this.localStatus)
    },
    shouldHideDot() {
      return !this.localStatus.includes('start')
    },
    signalTagType() {
      if (this.localStatus === 'started') {
        return 'green'
      } else if (this.localStatus === 'paused') {
        return 'orange'
      } else if (this.localStatus === 'stopped') {
        return 'grey'
      } else if (this.localStatus === 'error') {
        return 'red'
      }
    },
    signalTagText() {
      if (this.localStatus === 'started') {
        return 'Active'
      } else if (this.localStatus === 'paused') {
        return 'Paused'
      } else if (this.localStatus === 'stopped') {
        return 'Stopped'
      } else if (this.localStatus === 'error') {
        return 'Error'
      } else {
        return ''
      }
    },
    automationNameInKebabCase() {
      if (!this.item) {
        return '';
      }
      return this.$arUtils.general.generateDataTestPrettyName(this.item.name);
    },
    isAutomationFeatureEnabled() {
      return this.isFeatureEnabled(['automation']);
    },
  },
  destroyed() {
    clearInterval(this.poller)
  },
  mounted() {
    this.checkStatus()
    this.adjustDropdownOptions()

    if (!this.isFeatureEnabled(['automation'])) {
      this.restrictMenuActions()
    }
  },
  methods: {
    ...mapActions({
      startAutomation: 'automation/START',
      pauseAutomation: 'automation/PAUSE',
      duplicateAutomation: 'automation/DUPLICATE',
      stopAutomation: 'automation/STOP',
      deleteAutomation: 'automation/DELETE',
      checkAutomationStatusSingle: 'automation/FETCH_AUTOMATION_STATUS_SINGLE',
      showConfirm: 'SHOW_CONFIRM',
    }),
    restrictMenuActions() {
      this.menuActions = this.menuActions.map(item => {
        if (item.name === 'Start' || item.name === 'Pause' || item.name === 'Stop') {
          return { ...item, disabled: true }
        } else {
          return { ...item }
        }
      })
    },
    deleteAutomationAction() {
      this.deleteAutomation({id: this.item.oid})
    },
    async deleteAutomationModal() {
      const ans = await this.showConfirm({
        title: 'Delete this automation?',
        messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(this.item.name)}</b>, are you sure you want to proceed? Campaigns, lists, or tags referenced in this automation will not be deleted.`,
        confirmButtonText: 'Delete',
        validation: {
          question: 'Type DELETE to confirm',
          answer: 'DELETE',
        },
        asyncCompleteFunction: this.deleteAutomationAction,
      });
    },
    adjustDropdownOptions() {
      if (this.item.status === 'error') {
        this.resetMenuOptions()
        return
      }
      let correspondingMenuOption
      switch (true) {
        case this.item.status.includes('stop'):
          correspondingMenuOption = 'Stop'
          break
        case this.item.status.includes('start'):
          correspondingMenuOption = 'Start'
          break
        case this.item.status.includes('paus'):
          correspondingMenuOption = 'Pause'
          break
      }

      this.menuActions.find(option => option.name === correspondingMenuOption).disabled = true
    },
    resetMenuOptions() {
      let i = 0
      let length = this.menuActions.length
      while (i < length) {
        if (!this.menuActions[i]?.type) {
          this.menuActions[i].disabled = false
        }
        i++
      }
    },
    handleCardClick() {
      this.$router.push({path: `automation/edit`, query: {oid: this.item.oid}})
    },
    async checkStatus() {
      this.localStatus = await this.checkAutomationStatusSingle({id: this.item.oid, withCommit: false})
    },
    async handleMenuOptionClick(id, item) {
      if (item.name === 'Delete') {
        await item.action({id})
        return
      }

      let origStatus = this.item.status

      this.menuActions.find(option => {
        if (option.key === item.key && !option?.type) {
          option.disabled = true
        } else {
          option.disabled = false
        }
      })

      try {
        this.poller = setInterval(this.checkStatus, 2000)
        await item.action({id})

        if (this.item.status === 'error') {
          this.setLocalStatus('recover-from-error')
        } else {
          this.setLocalStatus(item.name)
        }
      } catch (error) {
        this.menuActions.find(option => option.name === item.name).disabled = false
        if (origStatus !== 'new' && origStatus !== 'error') {
          this.menuActions.find(option => option.key === origStatus).disabled = true
        }
        this.localStatus = origStatus

        let errObj = Object.assign({}, error)
        this.handleErr(errObj['response'].data.message.details)
        console.error(error)
      }
    },
    handleErr(msg) {
      if (msg.includes("Configuration has errors")) {
        this.$arNotification.push({ type: 'error', message: `One or more items in Automation '${this.item.name}' is missing data.` })
      }
      if (msg.includes("Add blocks first")) {
        this.$arNotification.push({ type: 'error', message: `Card - Atleast one action must exist within your Automation.` })
      }
      clearInterval(this.poller)
      this.poller = null
    },
    setLocalStatus(status) {
      switch (status) {
        case 'Start':
          this.localStatus = 'should-start'
          break
        case 'Pause':
          this.localStatus = 'should-pause'
          break
        case 'Stop':
          this.localStatus = 'should-stop'
          break
        case 'recover-from-error':
          this.localStatus = 'recover-from-error'
          break
        default:
          this.localStatus = ''
          console.log('An unrecognised status was received:', status)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.automation-list-item-wrapper {
  width: 100%;
  padding: 27px 6px 27px 34px;
  border-radius: 4px;
  border: solid 1px #dcdee4;
  background-color: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  .automation-card-left-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .stats-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 5px;
    }
  }

  .automation-card-right-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    .loading-bubble-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-right: 55px;
    }

    .signal-tag {
      margin-right: 20px
    }
  }

  .auto-card-menu-wrapper {
    position: absolute;
    z-index: 10;
    width: 180px;
    top: 85px;
    right: -10px;
    background: white;
    border: 0.0625em solid $blueGrey500;
    border-radius: 5px;
    box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
    padding: 10px 0;

    &.menu-top {
      top: -140px;

      .triangle {
        top: unset;
        bottom: -6px;
        transform: rotate(180deg);
      }
    }

    .triangle {
      position: absolute;
      width: 10px;
      top: -5px;
      right: 34px;

      .triangle-outer {
        position: absolute;
        top: 0;
        left: 0;
        border-left: ui-px2em(5) solid transparent;
        border-right: ui-px2em(5) solid transparent;
        border-bottom: ui-px2em(5) solid $skyBlueGrey500;
      }

      .triangle-inner {
        position: absolute;
        top: 1px;
        left: 0;
        border-left: ui-px2em(5) solid transparent;
        border-right: ui-px2em(5) solid transparent;
        border-bottom: ui-px2em(5) solid white;
        transform: translateY(10%);
      }
    }

    .menu-option {
      padding: 6px 16px;

       &:hover {
        background-color: #f2edff;

        .auto-card-menu-text {
          color: $purple500;
        }
      }

      &.disabled {
        background-color: unset;
        pointer-events: none;

        .auto-card-menu-text {
          opacity: 0.4;
        }
      }
    }

    .menu-option > * {
      pointer-events: none;
    }
  }
}
</style>
