<template>
  <div class="action-item-container" :data-parent-outbox-id="action.parentOutBoxId" :data-block-id="action.block.id">
    <div
      :id="action.block.id"
      :class="`action-item-wrapper ${blockStatus}`"
      :data-key="action.block.key"
      @click="handleActionClick(action.block.id, $event)"
    >
      <am2-send-test-email-modal
        :opened="displaySendTestEmailModal"
        @cancel="handleSendTestEmailCancel"
        :automation-message-data="automationMessageData"
      />
      <div v-if="isEditEnabled" id="action-close" class="action-close-icon-wrapper" @click="handleDelete">
        <CloseCrossIcon class="action-close-icon"/>
      </div>
      <div class="action-send-test-email-wrapper">
        <ar-link-button
          v-show="showSendTestEmailButton"
          text="Send a test"
          data-test-id="test-email-button"
          :style="{
            height: '25px',
          }"
          align="left"
          @click="handleBlockAction"
        />
      </div>

      <div class="planner-item-container">
        <div class="auto-card-heading">
          <div
            v-html="require(`~/assets/icons/${action.block.icon}.svg?raw`)"
            :class="`auto-card-icon ${action.block.icon}-icon`"
          />
          <ar-text
            class="auto-item-text"
            size="14px"
            :text="action.block.name"
            weight="bold"
            multiple-lines
            :max-lines="1"
            line-height="2.14"
          />
        </div>

        <div class="card-sub-wrapper">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import CloseCrossIcon from '~/assets/icons/close-cross-icon.svg?inline'
export default {
  name: 'ActionBlock',
  components: {
    CloseCrossIcon
  },
  props: {
    action: {
      type: Object,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      displaySendTestEmailModal: false,
    }
  },
  computed: {
    ...mapState({
      isEditEnabled: state => state.automation.isEditEnabled,
    }),
    ...mapGetters({
      editableId: 'automation/getEditableId',
    }),
    isActiveElement() {
      return this.editableId === this.action.block.id
    },
    showSendTestEmailButton() {
      // There is a subject and a template for the Send Test email button to show
      return !!this.action.block?.config?.subject && !!this.action.block?.config?.['bucket-email-template-oid'] && !!this.action.block?.meta?.messageSender
    },
    automationMessageData() {
      const block = this.action.block
      if (!block.config) {
        return null
      }

      return {
        taskOid: null,
        automationBlockOid: block.id,
        meta: {
          messageBody: {
            subject: block.config.subject,
            previewText: block.config['preview-text'],
          },
          email: {
            promoterPropertyOid: block.config['promoter-property-oid'],
          },
        },
      }
    },

    blockStatus() {
      let status = ""
      if (this.hasError) {
        status = 'block-error border-red '
      } else {
        status = 'border-green '
      }

      return `${status}${this.isActiveElement ? 'active' : ''}`
    }
  },
  methods: {
    handleActionClick(id, event) {
      this.$emit('click', {id, event})
    },
    handleDelete() {
      this.$emit('action-delete')
    },
    handleBlockAction() {
      this.displaySendTestEmailModal = true;
    },
    handleSendTestEmailCancel() {
      this.displaySendTestEmailModal = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.action-item-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;

  .action-item-wrapper {
    border-radius: 3px;
    border: solid 0.2px rgba(194, 194, 194, 0.3);
    background-color: white;
    padding: 10px 20px;
    width: 325px;
    margin-left: 9px;
    margin-right: 9px;
    cursor: pointer;
    position: relative;
    min-height: 51px;

    &:hover {
      .action-close-icon-wrapper {
        display: flex;
      }
    }

    &.active {
      border: 1px solid $green500;
      box-shadow: 0 0 0 3px $green200;

      &.block-error {
        box-shadow: 0 0 0 3px $red200;
      }
    }

    &.block-error {
      border: 1px solid $red500;
    }

    &.border-green {
      border-left: 6px solid $green500;
    }

    &.border-red {
      border-left: 6px solid $red500;
    }

    .action-close-icon-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      padding: 7px;
      display: none;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      .action-close-icon {
        pointer-events: none;
        width: 10px;
        height: 10px;
        color: #a8aebb;
      }
    }

    .action-send-test-email-wrapper {
      position: absolute;
      right: 24px;
      top: 0;
      padding-top: 10px;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
    }

    .planner-item-container {
      pointer-events: none;

      .auto-card-heading {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;

        .auto-card-icon {
          margin-right: 8px;
          margin-bottom: 0.2rem;
        }

        .calendar-icon {
          width: 1.1rem;
          height: 1.1rem;
          margin-top: -2px;
        }

        .contacts-icon {
          width: 17px;
          height: 17px;
          margin-top: -2px;
        }

        .clock-icon {
          width: 1.4rem;
          height: 1.4rem;
        }

        .message-icon {
          margin-bottom: 0.1rem;
        }

        .fingerprint-icon {
          width: 21px;
          height: 21px;
          margin-top: 1px;
        }
      }
    }
  }
}
</style>
