<template>
  <EditableFrame
    class="registers-for-campaign-options-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    :block-type="item.type"
    @close="$emit('close')"
    @save="handleSave"
    @click.native="handleClickOutside"
  >
    <ar-text
      class="option-heading"
      size="13px"
      text="CAMPAIGNS"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="reg-campaign-container">
      <div class="reg-campaign-dropdown-wrapper" @click="toggleOptions" data-test-id="reg-campaign-toggle">
        <ar-text
          class="reg-campaign-copy"
          size="14px"
          :text="messageListText"
          multiple-lines
          :max-lines="1"
          line-height="20px"
        />
        <ArrowHead
          class="reg-campaign-dropdown-arrowhead"
          :class="listOpen && 'rotate'"
          height="8px"
          width="10px"
          fill="#8492a6"
        />
      </div>
      <div v-if="listOpen" class="reg-campaign-search-wrapper">
        <am2-search
          class="reg-campaign-search"
          v-model="listSearchText"
          placeholder="Search campaigns..."
          data-test-id="reg-campaign-search"
        />
        <div class="campaign-list-wrapper">
          <div v-if="noMatchingCampaigns" class="campaign-link no-campaign-found-wrapper">
            <div class="item-wrapper">
              <ar-text
                class="item-name"
                :style="{ marginRight: '4px' }"
                size="xs"
                text="No matching campaign found"
                multiple-lines
                :max-lines="1"
                line-height="20px"
                weight="normal"
              />
            </div>
          </div>
          <template v-for="campaign in filteredCampaignsList">
            <a
              :key="campaign.oid"
              class="campaign-link"
              @click="() => setSelectedListOid(campaign.oid)"
              :data-test-id="`reg-campaign-anchor-${campaign.oid}`"
            >
              <div class="item-wrapper">
                <ar-text
                  :class="[
                    'item-name',
                    campaign.oid === selectedCampaignOid && 'selected'
                  ]"
                  size="xs"
                  :text="campaign.name"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  :weight="campaign.oid === selectedCampaignOid ? 'bold' : 'normal'"
                />
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'RegistersForCampaign',
  components: {
    EditableFrame,
    ArrowHead,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      listOpen: false,
      listSearchText: '',
      filteredCampaignsList: [],
      selectedCampaignOid: null,
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    campaigns: {
      immediate: true,
      handler() {
        this.filteredCampaignsList = this.campaigns
      }
    },
    listSearchText() {
      this.filterCampaigns(this.listSearchText)
    }
  },
  computed: {
    ...mapGetters({
      campaigns: 'campaign/campaignsList',
    }),
    noMatchingCampaigns() {
      return !this.filteredCampaignsList.length
    },
    messageListText() {
      if (!this.selectedCampaignOid || !this.campaigns.length) return 'Select a campaign...'
      return this.campaigns.find(item => item.oid === this.selectedCampaignOid).name
    },
    savedButtonDisabled() {
      return !this.selectedCampaignOid
    }
  },
  methods: {
    ...mapActions({
      fetchCampaigns: 'automation/FETCH_CAMPAIGN_LIST',
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleClickOutside(e) {
      if (!this.listOpen) return

      let clickOutsideClasses = ['content-wrapper', 'header-wrapper', 'heading-close-icon-wrapper', 'footer-wrapper', 'reg-campaign-container']
      if (clickOutsideClasses.includes(e.target.className)) {
        this.listOpen = false
        this.listSearchText = ''
      }
    },
    setSelectedListOid(oid) {
      this.selectedCampaignOid = oid
      this.listOpen = false
    },
    filterCampaigns(text) {
      let filteredCampaignsList = []
      let i = 0
      while (i < this.campaigns.length) {
        if (this.$arFuzzySearch(this.campaigns[i].name, text)) {
          filteredCampaignsList.push(this.campaigns[i])
        }

        i++
      }

      this.filteredCampaignsList = filteredCampaignsList
    },
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    handleSave() {
      let campaign = this.campaigns.find(item => item.oid === this.selectedCampaignOid)
      let contextData = {
        config: {
          'campaign-oid': this.selectedCampaignOid,
        },
        meta: {
          name: campaign.name,
        }
      }

      this.updateTriggerContextData({id: this.item.id, data: contextData})
      this.$emit('close')
    },
    resetDefaults() {
      this.selectedCampaignOid = null
      this.listOpen = false
      this.listSearchText = ''
    },
    setItemVals() {
      this.resetDefaults()
      if (this.item.config?.['campaign-oid']) {
        this.selectedCampaignOid = this.item.config['campaign-oid']
      }
    }
  },
  fetch() {
    this.fetchCampaigns()
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.registers-for-campaign-options-wrapper {
  width: 100%;
  height: 100%;

  .option-heading {
    margin-bottom: 14px;
    pointer-events: none;
  }

  .reg-campaign-container {
    position: relative;
    .reg-campaign-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(14) ui-px2em(18) ui-px2em(16) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      margin-bottom: 10px;
      cursor: pointer;

      .reg-campaign-copy {
        width: 94%;
      }

      .reg-campaign-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }
    .reg-campaign-search-wrapper {
      position: absolute;
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      overflow: scroll;
      z-index: 10;
      margin-bottom: 10px;

      .reg-campaign-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .campaign-list-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 260px;
        overflow: auto;
        margin-bottom: 10px;

        .campaign-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          &.no-campaign-found-wrapper:hover {
            background: unset;
            cursor: default;
          }

          .item-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;

            .item-name {
              &.selected {
                color: $purple500;
              }
            }
          }
        }
      }
    }
  }
}
</style>
