<template>
  <section>
    <ar-text
      size="xxs"
      text="STEP 2"
      :style="{
        color: '#9FA8B5',
      }"
      weight="bold"
    />
    <am2-heading
      class="u-margin-top-2"
      type="h1"
      size="lg"
      title="Form fields"
      multiple-lines
    />
    <!-- Form fields -->
    <ar-text
      class="u-margin-top-12"
      size="md"
      text="Select fields to display"
    />
    <am2-card-container
      :class="[
        'u-margin-top-7',
        'u-padding-y-6',
        'u-padding-x-6'
      ]"
    >
      <!-- section heading -->
      <div
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-margin-bottom-4',
        ]"
      >
        <ar-text
          class="u-flex-grow-1"
          size="xs"
          :text="$arMediaQuery.pageContent.maxWidth('xs') ? 'Fields' : 'Registration fields'"
          weight="bold"
        />
        <ar-text
          size="xs"
          text="Optional"
          align="left"
          weight="bold"
          :style="{
            width: $arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
          }"
        />
        <ar-text
          size="xs"
          text="Enabled"
          align="right"
          weight="bold"
          :style="{ width: '62px' }"
        />
      </div>
      <draggable v-model="savedFields" @end="handleEndDrag">
        <div
          v-for="(field) of formFields"
          :key="field.key"
        >
          <div
            :class="[
              'u-display-flex',
              'u-align-items-center',
              'u-justify-content-space-between'
            ]"
            :style="{
              height: '52px',
            }"
          >
            <div :class="[
              'u-display-flex',
              'u-align-items-center',
              'u-justify-content-space-between'
            ]"   style="max-width: 50%;" >
              <ar-text
                class="u-flex-grow-1"
                size="xs"
                :text="field.name"
              />
              <ar-icon
                v-if="!basicFieldsKeys[field.key]"
                class="icon edit-button u-margin-left-2 u-cursor-pointer"
                name="edit"
                height="10px"
                width="10px"
                stroke-width="4"
                stroke-linecap="round"
                :color="$arStyle.color.blueGrey700"
                @click="handleEditCustomField(field)"
              />
            </div>

            <div :class="[
              'u-display-flex',
              'u-align-items-center',
              'u-justify-content-space-between'
            ]">
              <ar-checkbox
                v-show="true"
                :value="field.optional"
                @input="handleRequiredCheck(field.key, ...arguments)"
                :disabled="field.isMandatory"
                align="left"
                :style="{
                width: $arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
              }"
              />
              <am2-switch
                :value="field.enabled"
                @input="handleClickEnable(field.key, ...arguments)"
                :disabled="field.isMandatory"
                v-tooltip="{
                content: field.isMandatory && field.mandatoryTooltip,
              }"
              />
            </div>
          </div>
          <ar-divider />
        </div>
      </draggable>
      <div
        v-if="showMoreFields"
        :class="[
            'u-display-flex',
            'u-align-items-center',
          ]"
        :style="{
            height: '52px',
          }"
      >
        <ar-text
          class="u-flex-grow-1"
          size="xs"
          :text="'Custom field'"
        />
        <ar-link-button
          :text="'+ Add custom field'"
          @click="clickSignupCustomFieldModal"
        />
      </div>
      <div class="u-margin-top-5">
        <ar-link-button
          :text="showMoreFields ? 'See fewer fields' : 'See all'"
          @click="handleSeeMoreClick"
        />
      </div>
    </am2-card-container>
    <ar-text
      class="u-margin-top-12"
      size="md"
      text="Verify your registrants"
    />
    <am2-switch-field-card
      :class="[
        'u-margin-top-5',
      ]"
      :style="{
        padding: '16px 24px',
      }"
      title="Email verification"
      subtitle="Registrants will be required to confirm their email address via a code"
      :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
      :value="emailVerification"
      :display-field="emailVerification"
      @input="handleEmailVerification"
    />
    <ar-text
      class="u-margin-top-12"
      size="md"
      text="Privacy"
    />
    <am2-switch-field-card
      :class="[
        'u-margin-top-5',
      ]"
      :style="{
        padding: '16px 24px',
      }"
      title="Enable checkboxes for GDPR/CCPA/TCPA"
      :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
      :value="tcEnabled"
      :display-field="tcEnabled"
      @input="handleSubscriptionPreferenceSettingsEnableChange"
    >
      <div class="disclaimer-wrapper">
        <ar-field
          v-if="displayCustomDisclaimerField && !!tcEnabled"
          label="Disclaimer"
        >
          <ar-input
            placeholder="I would like to receive marketing communications by"
            :value="tcDisclaimer"
            @input="handleSubscriptionPreferenceSettingsDisclaimerInput"
          />
        </ar-field>
        <ar-link-button
          v-if="!displayCustomDisclaimerField && !!tcEnabled"
          text="Add custom disclaimer"
          @click="toggleShowCustomDisclaimer"
        />
      </div>
    </am2-switch-field-card>
    <am2-switch-field-card
      :class="[
        'u-margin-top-5',
      ]"
      :style="{
        padding: '16px 24px',
      }"
      title="Add your Privacy Policy link"
      :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
      :value="showPrivacyPolicy"
      :display-field="showPrivacyPolicy"
      @input="toggleShowPrivacyPolicy"
    >
      <ar-field
        :error-messages="[
          veeErrors.first('privacyPolicyCompanyName'),
          veeErrors.first('privacyPolicyUrl'),
        ]"
      >
        <div
          :class="[
            'u-display-flex',
            'u-justify-content-space-between',
            $arMediaQuery.pageContent.maxWidth('xs') && 'u-flex-flow-column',
            'u-margin-top-3'
          ]"
        >
          <ar-input
            placeholder="Company"
            :value="privacyPolicyCompanyName"
            @input="handlePrivacyPolicyCompanyNameInput"
            v-validate="'required|privacyPolicyUrlAlsoRequired'"
            autocomplete="organization"
            data-vv-name="privacyPolicyCompanyName"
            data-vv-as="company name"
            type="text"
            :style="{
              width: $arMediaQuery.pageContent.maxWidth('xs') ? '100%' : '30%',
            }"
          />
          <ar-input
            :class="[
              $arMediaQuery.pageContent.maxWidth('xs') && 'u-margin-top-2',
            ]"
            placeholder="Privacy Policy URL"
            :value="privacyPolicyUrl"
            @input="handlePrivacyPolicyUrlInput"
            v-validate="{
              required: true,
              privacyPolicyCompanyNameAlsoRequired: true,
              url: {
                require_protocol: true,
              },
            }"
            autocomplete="url"
            data-vv-name="privacyPolicyUrl"
            data-vv-as="url"
            type="text"
            :style="{
              width: $arMediaQuery.pageContent.maxWidth('xs') ? '100%' : 'calc(70% - 10px)',
            }"
          />
        </div>
      </ar-field>
    </am2-switch-field-card>
    <am2-select-edit-create-custom-field-modal
      :open="showSignupCustomFieldModal"
      @save="handleCustomFieldSave"
      @close="cancelSignupCustomField"
      @fieldSelected="handleCustomFieldSelected"
      :create-state="customFieldCreateState"
      :selected-custom-field="editCustomField"
      :restricted-mode="true"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import CustomFieldsModal from "~/pages/campaigns/modify/components/custom-fields-modal/CustomFieldsModal.vue";
import { clone } from '@/utils/helpers';

const MIN_SHOWING_ITEMS_COUNT = 4

export default {
  name: 'Details',

  data() {
    return {
      showMoreFields: false,
      showPrivacyPolicy: false,
      displayCustomDisclaimerField: false,
      showSignupCustomFieldModal: false,
      editCustomField: null,
      editCustomFieldIndex: null,
      savedFields: [],
      basicFieldsKeys: {
        emailAddress: true,
        firstName: true,
        lastName: true,
        mobileNumber: true,
        dob: true,
        gender: true,
        postcode: true,
        city: true,
        state: true,
        country: true,
      },
      customFieldCreateState: 'select'
    };
  },

  computed: {
    ...mapState({
      scratchSignupForm: state => state.signupForm.scratchSignupForm,
      messageListInScratchSignupForm: state => state.signupForm.messageListInScratchSignupForm,
      formFieldInstances: state => state.signupForm.formFieldInstances,
      promoterOid: state => state.auth.account.promoterOid,
    }),
    ...mapGetters({
      channelValueInScratchSignupForm: 'signupForm/channelValueInScratchSignupForm',
    }),
    privacyPolicy() {
      return this.scratchSignupForm.presentation?.meta?.register?.privacyPolicy
    },
    privacyPolicyUrl() {
      return this.privacyPolicy?.url
    },
    privacyPolicyCompanyName() {
      return this.privacyPolicy?.companyName
    },
    subscriptionPreferenceSettings() {
      return this.scratchSignupForm.presentation?.meta?.register?.subscriptionPreferenceSettings
    },
    emailVerification() {
      return this.scratchSignupForm.settings?.emailVerification
    },
    tcEnabled() {
      return this.subscriptionPreferenceSettings?.enabled
    },
    tcDisclaimer() {
      return this.subscriptionPreferenceSettings?.disclaimer
    },
    formFields() {
      let foundedIndex = this.findLastEnabledItemIndex();
      if (foundedIndex < MIN_SHOWING_ITEMS_COUNT && MIN_SHOWING_ITEMS_COUNT < this.savedFields.length - 1)
        foundedIndex = MIN_SHOWING_ITEMS_COUNT;
      return this.showMoreFields ? this.savedFields : [...this.savedFields].slice(0, foundedIndex)
    },
    campaignOid() {
      return this.scratchSignupForm?.oid;
    },
    selectedCustomFields() {
      return this.savedFields.filter( item => !item.predefined && !!item.oid)
    },
  },

  watch: {
    scratchSignupForm: {
      handler() {
        if (!!this.privacyPolicyCompanyName || !!this.privacyPolicyUrl) {
          this.showPrivacyPolicy = true
        }

        if (!this.privacyPolicyCompanyName && !this.privacyPolicyUrl && this.showPrivacyPolicy) {
          this.showPrivacyPolicy = false
          this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { privacyPolicy: { companyName: null, url: null }}}}})
        }

        if (!!this.tcEnabled && !!this.tcDisclaimer) {
          this.displayCustomDisclaimerField = true
        }

        this.updateCustomFieldFromForm()
      },
      immediate: true,
    },
    tcDisclaimer() {
      if (!!this.tcEnabled && !this.tcDisclaimer) {
        this.displayCustomDisclaimerField = false
      }
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),
    ...mapMutations([
      'signupForm/PUT_SCRATCH_SIGNUP_FORM',
    ]),

    handleSeeMoreClick() {
      this.showMoreFields = !this.showMoreFields;
    },
    toggleShowCustomDisclaimer() {
      this.displayCustomDisclaimerField = !this.displayCustomDisclaimerField
      if (!this.displayCustomDisclaimerField) {
        this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { subscriptionPreferenceSettings: { disclaimer: null }}}}})
      }
    },
    handleClickEnable(fieldKey, value) {
      let patchSignupFormData = {
        registrations: {
          fields: {},
          requiredFields: {},
        }
      };

      const index = this.savedFields.findIndex(el => el.key === fieldKey);
      this.savedFields[index].enabled = value;
      patchSignupFormData.registrations.fields = this.savedFields;

      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    },
    handleRequiredCheck(fieldKey, isOptional) {
      let patchSignupFormData = {
        registrations: {
          fields: {},
          requiredFields: {},
        }
      };

      patchSignupFormData.registrations.requiredFields[fieldKey] = !isOptional;
      const index = this.savedFields.findIndex(el => el.key === fieldKey);
      this.savedFields[index].optional = isOptional;
      patchSignupFormData.registrations.fields = this.savedFields;

      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    },

    handleEmailVerification(enabled) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { emailVerification: enabled }});
    },

    handleSubscriptionPreferenceSettingsEnableChange(enabled) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { subscriptionPreferenceSettings: { enabled }}}}});
      if (!enabled) {
        this.displayCustomDisclaimerField = false
        this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { subscriptionPreferenceSettings: { disclaimer: null }}}}});
      }
    },

    handleSubscriptionPreferenceSettingsDisclaimerInput(data) {
      let disclaimer = data.length ? data : null
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { subscriptionPreferenceSettings: { disclaimer }}}}});
    },

    handlePrivacyPolicyCompanyNameInput(companyName) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { privacyPolicy: { companyName }}}}})
    },

    handlePrivacyPolicyUrlInput(url) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { privacyPolicy: { url }}}}})
    },

    toggleShowPrivacyPolicy() {
      this.showPrivacyPolicy = !this.showPrivacyPolicy;
      if (!this.showPrivacyPolicy) {
        this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ presentation: { meta: { register: { privacyPolicy: { companyName: null, url: null }}}}})
      }
    },

    cancelSignupCustomField() {
      this.editCustomField = null
      this.showSignupCustomFieldModal = false;
      this.customFieldCreateState = 'select';
    },

    clickSignupCustomFieldModal(createState = 'select') {
      this.customFieldCreateState = createState;
      this.showSignupCustomFieldModal = true;
    },
    appendCustomFieldToRegoOptions(field) {
      const newCustomField = {
        ...field,
        key: field.name,
        predefined: false,
        enabled: true,
        type: field.fieldType,
        campaignOid: this.campaignOid,
      }
      const selectedIndex = this.savedFields.length;
      const newSelectedFields = this.savedFields.concat(newCustomField)
      if (selectedIndex !== null && selectedIndex !== undefined) {
        newSelectedFields[selectedIndex] = {
          ...newSelectedFields[selectedIndex],
        }
      }
      this.savedFields = clone(newSelectedFields)
      const patchSignupFormData = {
        registrations: {
          fields: this.savedFields,
        }
      };
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    },
    overwriteExistingFieldInRegoOptions(field) {
      let newSelectedFields = clone(this.savedFields);
      let selectedIndex = this.editCustomFieldIndex;
      const oldKey = !!this.savedFields ? (this.savedFields[selectedIndex]?.name || null) : null
      const updatedExistingField = {
        ...this.savedFields[this.editCustomFieldIndex],
        ...field
      }
      const newCustomField = {
        ...updatedExistingField,
        key: updatedExistingField.name,
        predefined: false,
        enabled: true,
        type: updatedExistingField.fieldType,
        campaignOid: this.campaignOid,
      }
      if (oldKey != null) {
        // Find the existing selected field based on the old key
        selectedIndex = this.savedFields.findIndex((f) => f.predefined === false && f.key === oldKey)
        if (selectedIndex != null && selectedIndex != undefined) {
          newSelectedFields[selectedIndex] = newCustomField
        }
      }
      this.savedFields = clone(newSelectedFields)
      const patchSignupFormData = {
        registrations: {
          fields: this.savedFields,
        }
      };
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    },
    handleCustomFieldSelected(field) {
      const existingValueIndex = this.savedFields.findIndex( item => item.oid === field.oid );
      if (existingValueIndex > -1) {
        this.$arNotification.push({ type: 'error', message: 'This custom field has already been added' });
        return;
      }
      this.appendCustomFieldToRegoOptions(field)
      this.showSignupCustomFieldModal = false
      this.editCustomField = null
      this.editCustomFieldIndex = null
      this.customFieldCreateState = 'select'
    },
    async handleCustomFieldSave(editingMode, field) {
      if (editingMode === 'new' || editingMode === 'select') {
        const data = await this.$api.customFields.createCustomField({
          promoterOid: this.promoterOid,
          ...field,
        })
        this.appendCustomFieldToRegoOptions(data)
      } else if (editingMode === 'edit') {
        if (field.visibility === 'profile') {
          this.$arNotification.push({ type: 'error', message: 'Cannot edit Profile fields from within a campaign' });
          return;
        }
        let regoField = {};
        const foundFormFieldInstance = this.formFieldInstances.find( item => {
          return item.promoterCustomFieldOid === field.oid
        })
        if (this.campaignOid && !!foundFormFieldInstance?.oid) {
          const updatePayload = {
            ...foundFormFieldInstance,
            options: field.options || foundFormFieldInstance.options,
          }
          regoField = {
            ...updatePayload,
            oid: updatePayload.promoterCustomFieldOid,
          }
          await this.$api.customFields.updateFormFieldInstance(updatePayload)
        } else {
          regoField = field;
        }

        this.overwriteExistingFieldInRegoOptions(regoField);
      }
      this.showSignupCustomFieldModal = false
      this.editCustomField = null
      this.editCustomFieldIndex = null
      this.customFieldCreateState = 'select'
    },

    async handleEditCustomField(field) {
      const index = this.scratchSignupForm?.registrations?.fields.findIndex((f) => (f.predefined === false && f.name === field.name));
      if (!!field.oid) {
        const customField = await this.$api.customFields.getCustomField(this.promoterOid, field.oid);
        const foundFormFieldInstance = this.formFieldInstances.find( item => {
          return item.promoterCustomFieldOid === customField.oid
        })
        let data;
        if (customField.visibility === 'form') {
          if (!!foundFormFieldInstance) {
            data = {
              ...customField,
              ...foundFormFieldInstance,
              oid: customField.oid,
            }
          } else {
            data = {
              ...customField,
            }
          }
        } else {
          data = customField;
        }

        this.editCustomField = data;
        this.editCustomFieldIndex = index;
        this.customFieldCreateState = 'edit';
        this.clickSignupCustomFieldModal(this.customFieldCreateState);
      } else {
        this.$arNotification.push({ type: 'error', message: 'Cannot edit this field. Please create a new one' });
      }
    },

    updateCustomFieldFromForm() {
      if (!!this.messageListInScratchSignupForm && !this.messageListInScratchSignupForm.sms && !!this.scratchSignupForm?.registrations?.fields) {
        const mobileNumberIndex = this.scratchSignupForm.registrations.fields.findIndex((field) => {
          return field.key === 'mobileNumber'
        })
        if (mobileNumberIndex > -1) {
          this.scratchSignupForm.registrations.fields[mobileNumberIndex].isMandatory = false
        }
      }

      this.savedFields = this.scratchSignupForm?.registrations?.fields;
    },

    findLastEnabledItemIndex() {
      return this.savedFields && this.savedFields.length > 0
        ? this.savedFields?.findIndex(el => el.enabled === false)
        : null;
    },

    handleEndDrag() {
      let patchSignupFormData = {
        registrations: {
          fields: this.savedFields,
        }
      };

      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    },

    // For all profile fields in the form, fetch them from the DB and overwrite them in the scratch form
    async fetchCurrentProfileFieldsAndUpdate() {
      const allRegistrationFields = this.scratchSignupForm?.registrations?.fields ?
        this.scratchSignupForm.registrations.fields :
        [];
      if (!allRegistrationFields || allRegistrationFields.length < 1) return;

      const currentCustomFieldsInForm = allRegistrationFields.filter(item => !item.predefined);
      if (currentCustomFieldsInForm.length < 1) return;

      const profileFieldsInForm = currentCustomFieldsInForm.filter( item => item.visibility === 'profile' && !!item.oid)
      if (profileFieldsInForm.length < 1) return;

      const profileFieldsFilterString = `oid = ${profileFieldsInForm.map( item => item.oid).join(" OR oid = ")}`;
      const dbProfileFields = await this.$api.customFields.getCustomFields(
        this.promoterOid,
        'profile',
        null,
        profileFieldsFilterString
      )

      dbProfileFields.forEach( dbField => {
        const regoFieldIdx = allRegistrationFields.findIndex( item => item.oid === dbField.oid);
        if (!regoFieldIdx) return;
        allRegistrationFields[regoFieldIdx] = {
          ...allRegistrationFields[regoFieldIdx],
          ...dbField
        }
      });

      let patchSignupFormData = {
        registrations: {
          fields: allRegistrationFields,
        }
      }
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    }
  },

  created() {
    // Logic is, if we have companyName, we check if we have URL or not
    this.$validator.extend('privacyPolicyUrlAlsoRequired', {
      getMessage: field => `You must enter a valid URL`,
      validate: value => {
        if (!value || !this.scratchSignupForm.presentation?.meta?.register?.privacyPolicy?.url) {
          return false;
        } else {
          return !!this.scratchSignupForm.presentation?.meta?.register?.privacyPolicy?.url
        }
      },
    });

    // Logic is, if we have url, we check if we have companyName or not
    this.$validator.extend('privacyPolicyCompanyNameAlsoRequired', {
      getMessage: field => `Please enter company name`,
      validate: value => {
        if (!value) {
          return false;
        } else {
          return !!this.scratchSignupForm.presentation?.meta?.register?.privacyPolicy?.companyName
        }
      },
    });
  },

  mounted() {
    this.updateCustomFieldFromForm();

    this.fetchCurrentProfileFieldsAndUpdate();
  }
};
</script>
