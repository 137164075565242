<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      header="Send a test email"
      width="707px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="send-test-email-modal"
    >
      <div slot="body" class="body">
        <ar-field
          label="Send a test email to"
          :error-messages="[veeErrors.first('email-addresses')]"
        >
          <div class="u-display-flex">
            <ar-input
              class="u-flex-grow-1"
              placeholder="bob@acme.com, jane@acme.com"
              v-model="emailAddresses"
              v-validate="{
                required: true,
                emailAddresses: true,
                maxTestEmailRecipients: { max: 9 },
              }"
              data-vv-name="email-addresses"
              data-vv-as="email addresses"
              autocomplete="email"
              @enter="handleSendTestEmailClick"
            />
            <ar-simple-button
              class="u-margin-left-3"
              text="Send test email"
              v-tooltip.top="messageCannotBeTested && 'Please go back and add a sender and subject line before testing'"
              outlined
              :loading="isUpdatingEmail || isSendingTestEmail"
              :disabled="messageCannotBeTested"
              :style="{
                width: '148px',
              }"
              @click="handleSendTestEmailClick"
            />
          </div>
          <ar-state-message
            v-if="emailSendToCopy"
            class="u-margin-top-3"
            type="success"
            has-icon
            disableTextColor
            :style="{
              wordBreak: 'break-word',
            }"
            :text="emailSendToCopy"
          />
          <ar-text
            class="u-margin-top-5"
            size="xs"
            text="Use commas to separate multiple emails"
            :style="{
              color: $arStyle.color.blueGrey700,
            }"
          />
        </ar-field>

        <ar-snackbar
              v-if="invalidUrls.length !== 0 || invalidEmailAddresses.length !== 0"
              type="warning"
              :message="warningMessage"
              class="u-margin-top-4"
          />

      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          text="Done"
          :style="{
            width: '140px',
          }"
          @click="handleDoneClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import { isValidUrl } from '@/utils/url';
import { findAllLinksInBeefreeTemplate } from '@/utils/message';

export default {
  name: 'SendTestEmailModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    automationMessageData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      invalidUrls: [],
      invalidEmailAddresses : [],
      emailAddresses: '',
      emailSendToCopy: '',
      scratchEmailWithInfo: "",
      generatedHtml: null,
    };
  },
  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      isSendingTestEmail: state => state.message.isSendingTestEmail,
      isUpdatingEmail: state => state.message.isUpdatingEmail,
      currentMessageSender: state => state.messageSender.currentMessageSender,
    }),
    ...mapGetters({
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      getBeefreePreviewAndFooterHtmlForInjection: 'message/getBeefreePreviewAndFooterHtmlForInjection',
      scratchEmailMessageCannotBeTested: 'message/scratchEmailMessageCannotBeTested',
    }),
    messageCannotBeTested() {
      if (!!this.automationMessageData) {
        return !this.automationMessageData.meta.messageBody.subject ||
        !this.automationMessageData.meta.email ||
        !this.automationMessageData.meta.email.promoterPropertyOid;
      } else {
        return this.scratchEmailMessageCannotBeTested
      }
    },
    invalidUrlsTextList() {
      const liList = this.invalidUrls.map(url => `<li>${url}</li>`).join('')
      return `<ul>${ decodeURIComponent(liList) }</ul>`
    },
    invalidEmailAddressesTextList() {
      const liList = this.invalidEmailAddresses.map(url => `<li>${url}</li>`).join('')
      return `<ul>${ decodeURIComponent(liList) }</ul>`
    },
    warningMessage() {
      return `
        ${this.invalidUrls.length > 0 || this.invalidEmailAddresses.length > 0 ? `<p><b>There are some invalid URLs in your email.</b></p>` : ''}
        ${this.invalidUrls.length > 0 ? `<p>Use https:// for secure links, and http:// for non-secure links.</p><p>The following URLs are affected: ${this.invalidUrlsTextList}</p>` : ''}
        ${this.invalidEmailAddresses.length > 0 ? `<p>Be sure to include a single @ in these email addresses: ${this.invalidEmailAddressesTextList}</p>` : ''}
        ${this.invalidUrls.length > 0 || this.invalidEmailAddresses.length > 0 ? `<p><a href="#">Return to the editor</a></p>` : ''}
      `;
    },
  },
  watch: {
    opened(val) {
      if (val) {
        // when the form is open, run a check for invalid urls
        this.checkEmailContentForValidUrls();
        this.emailSendToCopy = '';
        this.emailAddresses = '';
      }
    },
  },
  methods: {
    ...mapActions([
      'message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE',
      'message/SEND_TEST_EMAIL',
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
    ]),
    generateEmailSendToCopy() {
      const recipients = this.emailAddresses.split(',');
      let lastRecipient = null;
      if (recipients.length > 1) {
        lastRecipient = recipients.pop();
      }

      const emailSentString = lastRecipient ? `A test email was sent to <b>${recipients.join(', ')}</b> and <b>${lastRecipient}</b>. Check your inbox.` : `A test email was sent to <b>${recipients[0]}</b>. Check your inbox.`
      this.emailSendToCopy = emailSentString;
    },
    async validateFields() {
      await this.$validator.reset();
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    handleModalClose() {

      // we reset the email addresses here, so that if the template is edited, the email addresses will be cleared and re-checked
      this.invalidEmailAddresses = []
      this.invalidUrls = []
      
      // close modal
      this.$emit('cancel');
    },
    handleDoneClick() {
      this.$emit('cancel');
    },

    checkEmailContentForValidUrls(){
      
      // html for the email template
      const html = this.scratchEmailMessage?.meta?.presentation?.template ;
      // console.log( 'html', html );

      // the urls that exist within the beefree template
      const urls = findAllLinksInBeefreeTemplate(html);

      // we split these urls out into two lists, defined as part of the component data()
      this.invalidUrls = urls.filter(url => !isValidUrl(url) && !url.includes('@')); // regular URLs
      this.invalidEmailAddresses = urls.filter(url => url.includes('@') && !isValidUrl(url)); // email addresses

      // testing the values: 
        // console.log( 'invalid url:' , this.invalidUrls );
        // console.log( 'invalid email addresses:' , this.invalidEmailAddresses );
      
      // test output the for list lengths:
        // console.log( this.invalidUrls.length !== 0 ,  this.invalidEmailAddresses.length !== 0 )

    },

    async handleSendTestEmailClick() {
      // validate the modal fields
      const isValid = ( await this.validateFields() );

      // check if is valid, and if there are 0 invalid urls or 0 invalid email addresses
      if (!isValid && this.emailHasValidUrls) {
        return;
      }

      const recipients = this.emailAddresses.replace(/\s/g, '').split(',')

      if (!!this.automationMessageData) {
        await this['message/SEND_TEST_EMAIL']({
          type: "email",
          automationBlockOid: this.automationMessageData.automationBlockOid,
          taskOid: null,
          recipients,
        });
      } else {
        // legacy emails need to upload templates to convert to html
        if (!this.scratchEmailMessage.abEmail) {
          await this.getScratchEmailWithInfo()

          if (this.scratchEmailMessage.meta.presentation.templateType === 'unlayer') {
            const emailTemplateUpdated = await this['message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE']({
              emailHtml: this.scratchEmailWithInfo
            });
            if (!emailTemplateUpdated) {
              return;
            }
          }
        }

        await this['message/SEND_TEST_EMAIL']({
          type: "email",
          taskOid: this.scratchEmailMessage.oid,
          recipients,
          meta: this.scratchEmailMessage.meta,
          html: this.scratchEmailWithInfo,
        });
      }

      this.generateEmailSendToCopy();
    },

    

    async getScratchEmailWithInfo() {
      if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
        const beefreeGeneratedHtml = await this.getBeefreePreviewAndFooterHtmlForInjection(this.currentMessageSender)
        const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
          design: this.scratchEmailMessage?.meta?.presentation?.template,
          previewHtml: beefreeGeneratedHtml?.previewHtml || null,
          footerHtml: beefreeGeneratedHtml?.footerHtml || null,
        })

        this.generatedHtml = beefreeGeneratedHtml
        this.scratchEmailWithInfo = html
      } else {
        // Unlayer
        this.scratchEmailWithInfo = this.injectInfoToScratchEmail(this.currentMessageSender);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.send-test-email-modal {
  .body {
    padding: 24px 29px 33px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 23px 29px;
  }
}
</style>
