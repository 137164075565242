import { clone } from '@/utils/helpers';

export const mergeDynamicTags = (...tagsSet: string[][]): string[] => {
	let dynamicTags: string[] = [];
	tagsSet.forEach(tags => {
		dynamicTags = dynamicTags.concat(clone(tags));
	});
	return dynamicTags.filter((tag, tagIdx) => {
		return dynamicTags.indexOf(tag) === tagIdx; // This will prevent duplicated tags
	});
};



/**
 * Find all links in the given Beefree template
 * @param {string} jsonHtml - Beefree template as a JSON string
 * @returns {string[]} All links in the template
 */

export function findAllLinksInBeefreeTemplate(jsonHtml : string) {
	const urls : string[] = [];

	function traverse(obj: any) {
		for (const key in obj) {
			if (typeof obj[key] === 'object') {
				traverse(obj[key]);
			} else if (typeof obj[key] === 'string') {
				const anchorRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
				let match;
				while ((match = anchorRegex.exec(obj[key])) !== null) {
					urls.push(match[1]);
				}
			}

			if (key === 'button' && obj[key] && typeof obj[key] === 'object' && obj[key].href) {
				urls.push(obj[key].href);
			}
		}
	}

	const jsonObj = JSON.parse(jsonHtml);
	traverse(jsonObj);
	return urls;
}
