<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Prepare to send"
      width="728px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="prepare-to-send-modal"
      no-body-overflow
    >
      <div slot="body" class="body">
        <am2-card-container class="u-padding-y-6 u-padding-x-7 u-margin-y-4 message-details">
          <div v-if="isFormComplete">
            <div class="u-display-flex u-align-items-center">
              <ar-icon :color="$arStyle.color.purple500" name="message-center" :class="['icon']" class="message-icon" />
              <div class="message">
                <ar-text
                  class="message-name"
                  size="sm"
                  weight="bold"
                  :text="formattedPreviewTitle"
                />
                <ar-text size="xs" :text="formattedPreviewDescription" />
              </div>
            </div>
            <am2-field
              name="linked-events"
              label="Link to an event"
              :style="{
                marginTop: '24px'
              }">
              <am2-select-search-events
                class="linked-event-dropdown"
                :value="selectedEvents"
                :default-label="linkedEventsDropdownPlaceholder"
                style-like-select
                :pre-selected-events="preSelectedEvents"
                @changeSelectedOids="handleChangeOids"
              />
            </am2-field>
            <ar-snackbar
              v-if="emailAddressIsRestricted"
              type="warning"
              message="<b>Domains from email providers like @gmail.com are invalid. <a>Please create a new authenticated domain</a>.</b>"
              class="u-margin-top-4"
              @anchorClick="handleDomainAuthClick"
            />
            <ar-snackbar
              v-if="domainIsRestricted"
              type="warning"
              message="<b>Your sender domain is not authenticated. <a>Please authenticate this domain</a>.</b>"
              class="u-margin-top-4"
              @anchorClick="handleDomainAuthClick"
            />
            <ar-snackbar
              v-if="invalidUrls.length !== 0 || invalidEmailAddresses.length !== 0"
              type="warning"
              :message="warningMessage"
              class="u-margin-top-4"
              @anchorClick="handleClickFixInvalidUrls"
            />
          </div>
          <div v-if="!isFormComplete">
            <div class="u-display-flex u-align-items-center">
              <ar-icon
                :color="$arStyle.color.purple500"
                name="alert-exclamation-mark"
                :class="['icon']"
                class="message-icon"
              />
              <div class="message">
                <ar-text
                  class="message-name"
                  size="sm"
                  weight="bold"
                  :text="`${scratchEmailMessage.customerName || 'Email'} isn't ready to send`"
                />
                <div class="error-message">
                  <span class="error-message__text">You still need to complete the </span>
                  <span v-for="(item, index) in validationErrors" :key="item.label">
                      <span
                        class="error-message__error-item"
                        @click="handleClickError(item)"
                      >{{ item.label }}</span>{{ (index + 1 !== validationErrors.length) ? ', ' : '' }}
                    </span>
                  <span class="error-message__text">{{ validationErrors.length > 1 ? 'sections' : 'section' }}</span>
                  <span class="error-message__text">of your email campaign.</span>
                </div>
              </div>
            </div>
          </div>
        </am2-card-container>
        <am2-card-container class="u-padding-y-6 u-padding-x-7 u-margin-y-4 schedule-message">
          <div class="u-display-flex u-align-items-center u-justify-content-space-between">
            <div><ar-text size="xs" weight="bold" text="Schedule Message" /></div>
            <am2-switch
              :value="enableSchedule"
              @input="handleEnableScheduleToggle"
              :disabled="!currentMessageListCanBeSchedued || isUnlayerMessage"
              v-tooltip.top="{
                content: noScheduleTooltip
              }"
            />
          </div>
          <div
            v-if="enableSchedule"
            class="u-margin-top-6"
            :style="{
              display: 'flex',
              alignItems: 'baseline'
            }"
          >
            <div class="u-flex-grow-1">
              <am2-date-time-input
                :value="scheduledAt"
                @input="handleScheduledAtInput"
                :time-zone="timeZone"
                has-label
                v-validate="'required|tzFormat|afterNow|after5MinFromNow'"
                data-vv-name="scheduledAt"
                data-vv-as="scheduled time"
                :style="{
                  width: '100%'
                }"
                data-test-id="message-scheduled-at"
              />
              <ar-state-message
                v-if="veeErrors.has('scheduledAt')"
                :style="{ marginTop: '8px' }"
                type="error"
                :text="veeErrors.first('scheduledAt')"
              />
            </div>
            <ar-divider
              :style="{
                position: 'relative',
                top: '38px',
                width: '20px',
                flexShrink: '0',
                margin: '0 10px'
              }"
            />
            <div
              :style="{
                width: '205px',
                flexShrink: '0'
              }"
            >
              <ar-text
                class="u-margin-bottom-3"
                size="xs"
                text="Timezone"
                :style="{
                  color: $arStyle.color.blueGrey700
                }"
              />
              <am2-timezone-select
                :value="selectedTimezone"
                @input="handleTimezoneInput"
                v-validate="'required'"
                data-vv-name="timeZone"
                data-vv-as="timezone"
              />
              <ar-state-message
                v-if="veeErrors.has('timeZone')"
                :style="{ marginTop: '8px' }"
                type="error"
                :text="veeErrors.first('timeZone')"
              />
            </div>
          </div>
        </am2-card-container>
      </div>
      <div slot="footer" class="footer">
        <ar-link-button
          text="Cancel"
          :disabled="isEmailSending"
          @click="handleCancelClick"
          :style="{
            height: '40px',
            padding: '0 20px'
          }"
        />
        <ar-simple-button
          v-if="!enableSchedule"
          :disabled="!isFormComplete || emailAddressIsRestricted || domainIsRestricted || isInvalidUrls"
          text="Send Now"
          @click="handleSendClick"
          :loading="isEmailSending"
        />
        <ar-simple-button
          v-if="enableSchedule"
          :disabled="!isFormComplete || emailAddressIsRestricted || domainIsRestricted || isInvalidUrls"
          :loading="isEmailSending"
          text="Schedule Message"
          @click="handleSendClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import { isValidUrl } from '@/utils/url';
import { findAllLinksInBeefreeTemplate } from '@/utils/message';

export default {
  name: 'PrepareToSendModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    isEmailSending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      enableSchedule: false,
      formHasErrors: true,
      emailAddresses: '',
      scheduledAt: '',
      timeZone: '',
      invalidUrls: [],
      invalidEmailAddresses: [],
      preSelectedEvents: [],
    };
  },
  created() {
    this.$validator.extend('afterNow', {
      getMessage: field => `Scheduled time is invalid - time must be in the future`,
      validate: value => {
        return moment(value).isAfter(new Date());
      }
    });
    this.$validator.extend('after5MinFromNow', {
      getMessage: field => `Scheduled time is invalid - must be more than 5 minutes from now`,
      validate: value => {
        return moment(value).isAfter(moment().add(5, 'minutes'));
      }
    });
    this.fetchRegionSettings();
  },
  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      scratchEmailMessageVariants: state => state.message.scratchEmailMessageVariants,
      currentMessageList: state => state.messageList.currentMessageList,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      suggestedAudienceRecipientListCount: state => state.messageList.suggestedAudienceRecipientListCount,
      currentRegionSettings: state => state.auth.regionSettings,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      emailDomainRestricted: state => state.message.emailDomainRestricted,
      domainRestricted: state => state.message.domainRestricted,
      messageSenders: state => state.messageSender.messageSenders,
    }),
    ...mapGetters({
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus'
    }),
    selectedEvents() {
      if (this.scratchEmailMessage?.meta?.linkedEvents && this.scratchEmailMessage?.meta?.linkedEvents.length) {
        return this.scratchEmailMessage?.meta?.linkedEvents
      }
      return []
    },
    formattedPreviewTitle() {
      if (this.selectedEvents.length === 0) {
        return 'Your email is not linked to an event'
      }

      return `${this.scratchEmailMessage.customerName || 'Email'}`
    },
    formattedPreviewDescription() {
      if (this.selectedEvents.length === 0) {
        return 'This ensures that you can measure the ticket sales generated from this email.'
      }
      return `is ready to send to ${this.messageListRecipientCount} recipients`
    },
    linkedEventsDropdownPlaceholder() {
      if (this.selectedEvents.length === 1) return '1 event selected'
      return this.selectedEvents.length ? `${this.selectedEvents.length} events selected` : 'Select events...'
    },
    isUnlayerMessage() {
      return this.scratchEmailMessage?.meta?.presentation?.templateType === 'unlayer';
    },
    noScheduleTooltip() {
      if (!this.currentMessageListCanBeSchedued && !this.enableSchedule) {
        return 'You cannot schedule messages from campaigns'
      }
      if (this.isUnlayerMessage) {
        return 'This email was built in our legacy email builder and cannot be scheduled'
      }
      return null;
    },
    isFormComplete() {
      return this.validate().length === 0;
    },
    validationErrors() {
      return this.validate();
    },
    isInvalidUrls() {
      return this.invalidUrls.length !== 0
    },
    messageListRecipientCount() {
      // filtering doesn't work on campaign message lists so just use total from snapshot
      if (this.scratchEmailMessage?.meta?.suggestedAudience?.audienceOids?.length > 0) {
        return this.suggestedAudienceRecipientListCount;
      }

      if (!!this.currentMessageList?.campaignOid) {
        return this.currentMessageList?.statsSnapshot?.total || 0;
      }

      return this.filteredRecipientListCount;
    },
    selectedTimezone() {
      if (!!this.timeZone) {
        return this.timeZone
      } else if (this.currentRegionSettings) {
        return this.currentRegionSettings?.additionalInfo?.defaultTimezone || null
      } else {
        return null
      }
    },
    emailAddressIsRestricted() {
      return this.emailDomainRestricted;
    },
    domainIsRestricted() {
      return this.domainRestricted;
    },
    invalidUrlsTextList() {
      const liList = this.invalidUrls.map(url => `<li>${url}</li>`).join('')
      return `<ul>${ decodeURIComponent(liList) }</ul>`
    },
    invalidEmailAddressesTextList() {
      const liList = this.invalidEmailAddresses.map(url => `<li>${url}</li>`).join('')
      return `<ul>${ decodeURIComponent(liList) }</ul>`
    },
    warningMessage() {
      return `
        ${this.invalidUrls.length > 0 || this.invalidEmailAddresses.length > 0 ? `<p><b>There are some invalid URLs in your email.</b></p>` : ''}
        ${this.invalidUrls.length > 0 ? `<p>Use https:// for secure links, and http:// for non-secure links.</p><p>The following URLs are affected: ${this.invalidUrlsTextList}</p>` : ''}
        ${this.invalidEmailAddresses.length > 0 ? `<p>Be sure to include a single @ in these email addresses: ${this.invalidEmailAddressesTextList}</p>` : ''}
        ${this.invalidUrls.length > 0 || this.invalidEmailAddresses.length > 0 ? `<p><a href="#">Return to the editor</a></p>` : ''}
      `;
    },
  },
  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
        this.validateEmailUrls()
      }
    },
    selectedEvents(newVal, oldVal) {
      if (oldVal.length === 0 && newVal.length > 0) {
        this.fetchPreSelectedEvents()
      }
    },
  },

  methods: {
    ...mapMutations({
      patchScratchEmail: 'message/PATCH_SCRATCH_EMAIL_MESSAGE',
    }),
    ...mapActions({
      fetchFilteredRecipientListCount: 'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      fetchRegionSettings: 'auth/FETCH_REGION_SETTINGS',
      checkEmailDomainRestricted: 'message/CHECK_EMAIL_DOMAIN_RESTRICTED',
    }),
    async initEmailEditor() {
      if (this.scratchEmailMessage.scheduledAt != null) {
        this.scheduledAt = this.scratchEmailMessage.scheduledAt;
        this.timeZone = this.scratchEmailMessage.meta.presentation.timeZone;
        this.enableSchedule = true;
      }
      await this.$nextTick(() => {
        if (this.currentMessageSender?.property) {
          this.checkEmailDomainRestricted(this.currentMessageSender?.property);
        }

        this.fetchFilteredRecipientListCount({ channel: 'email' });
      });
    },
    handleModalClose() {
      this.$emit('close');
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    async handleSendClick() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$arNotification.push({ type: 'error', message: 'Could not send message, please review error messages' });
        return;
      }
      if (this.invalidUrls.length !== 0) {
        return
      }
      if (this.scheduledAt) {
        this.patchScratchEmail({
          meta: {
            presentation: {
              timeZone: this.timeZone
            }
          },
          scheduledAt: this.scheduledAt
        });
      }
      this.$emit('send');
    },
    handleScheduledAtInput(scheduledAt) {
      this.scheduledAt = scheduledAt;
    },
    handleTimezoneInput(timeZone) {
      this.timeZone = timeZone;
    },
    handleEnableScheduleToggle(toggle) {
      this.enableSchedule = toggle;
      if (!toggle) {
        this.clearScheduleTime();
      }
    },
    clearScheduleTime() {
      this.patchScratchEmail({
        meta: {
          presentation: {
            timeZone: null
          }
        },
        scheduledAt: null
      });
      this.scheduledAt = null;
      this.timeZone = null;
    },
    handleClickError(error) {
      this.$emit('clickError', error)
      this.$emit('close')
    },

    validateEmailUrls() {
      this.invalidUrls = [];
      this.invalidEmailAddresses = [];

      if (this.scratchEmailMessage?.meta?.presentation?.templateType !== 'beefree'
        || !this.scratchEmailMessage?.meta?.presentation?.template) {
        return;
      }
      const html = this.scratchEmailMessage.meta.presentation.template
      const urls = findAllLinksInBeefreeTemplate(html);

      // we split the urls with, and without @ symbols into two lists
      this.invalidUrls = urls.filter(url => !isValidUrl(url) && !url.includes('@')); // regular URLs
      this.invalidEmailAddresses = urls.filter(url => url.includes('@') && !isValidUrl(url)); // email addresses

    },
    handleClickFixInvalidUrls() {
      this.$emit('close');
      this.$emit('clickFixInvalidUrls', this.invalidUrls)
    },

    async handleChangeOids(oids) {
      this.patchScratchEmail({
        meta: {
          linkedEvents: oids || []
        }
      });
      this.fetchPreSelectedEvents()
    },

    validate() {
      const isABEmail = this.scratchEmailMessage.abEmail;
      const isABSubjectTreatment = this.scratchEmailMessage.abEmailType === 'subject';
      const messageMeta = this.scratchEmailMessage.meta;
      const messageVariants = this.scratchEmailMessageVariants;
      let errors = [];

      if (!messageMeta.messageListOid) {
        errors.push({ type: 'to', label: 'To' });
      }

      if (messageMeta.email.promoterPropertyOid) {
        const sender = this.messageSenders.find(sender => sender.oid === messageMeta.email.promoterPropertyOid)
        if (!sender || !sender.verified) {
          errors.push({ type: 'from', label: 'From' });
        }
      } else {
        errors.push({ type: 'from', label: 'From' });
      }

      if (isABEmail && isABSubjectTreatment) {
        for (const key of Object.keys(messageVariants)) {
          if (!messageVariants[key].meta.messageBody.subject) {
            errors.push({ type: 'subject', label: 'Subject ' + key, variant: key });
          }
        }
      } else {
        if (!messageMeta.messageBody.subject) {
          errors.push({ type: 'subject', label: 'Subject' });
        }
      }

      if (isABEmail && !isABSubjectTreatment) {
        for (const key of Object.keys(messageVariants)) {
          if (!messageVariants[key].meta.presentation.template) {
            errors.push({ type: 'design', label: 'Design ' + key, variant: key });
          }
        }
      } else {
        if (!messageMeta.presentation.template) {
          errors.push({ type: 'design', label: 'Design' });
        }
      }

      if (messageMeta.fallbackDynamicTags) {
        for (const key of Object.keys(messageMeta.fallbackDynamicTags)) {
          if (!messageMeta.fallbackDynamicTags[key]) {
            errors.push({ type: 'dynamicTagsFallback', label: 'Dynamic tags fallback' });
            break;
          }
        }
      }

      return errors;
    },
    handleDomainAuthClick() {
      window.open('https://audiencerepublic.freshdesk.com/support/solutions/articles/51000309976-domain-authentication', '_blank');
    },
    async fetchPreSelectedEvents() {
      if (this.selectedEvents.length === 0) {
        this.preSelectedEvents = []
        return
      }

      const data = await this.$axios.get(`/promoter/${this.promoterOid}/event/`, {
        params:{
          $top: 'all',
          $filter: `(oid=${this.selectedEvents.join(' OR oid=')})`,
        }
      })
      if (data.data && Array.isArray(data.data)) {
        this.preSelectedEvents = data.data
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.prepare-to-send-modal {
  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 32px;
    .schedule-message {
      width: 100%;
    }
    .message-details {
      width: 100%;
      .message {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .message-name {
        margin-bottom: 8px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    padding: 0 30px;
  }
  .message-icon {
    background: #f6f9fc;
    border-radius: 175px;
    width: 72px;
    height: 72px;
    justify-content: center;
    margin-right: 24px;
  }
  .error-message {
    white-space: normal;
    word-break: break-word;

    &__error-item {
      color: $purple500;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ::v-deep {
    .select-search-dropdown__option-wrapper {
      max-height: 250px !important;
    }
  }
}

.prepare-to-send-modal {
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 40px 0;
  background-color: rgba(31, 40, 68, 0.6);
  display: flex;
  align-items: center;
  ::v-deep {
    .ar-modal-mask {
      display: none;
    }

    .ar-modal-content {
      max-height: unset;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      margin: auto;
    }
  }
}
</style>
