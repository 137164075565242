var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditableFrame',{staticClass:"tag-added-wrapper",attrs:{"title":"Edit Trigger","subtitle":_vm.item.name,"has-save-button":"","save-button-disabled":_vm.savedButtonDisabled,"block-type":_vm.item.type},on:{"close":function($event){return _vm.$emit('close')},"save":_vm.handleSave},nativeOn:{"click":function($event){return _vm.handleClickOutside.apply(null, arguments)}}},[_c('ar-text',{staticClass:"tag-list-heading",attrs:{"size":"13px","text":"TAGS","weight":"bold","multiple-lines":"","max-lines":1,"letter-spacing":"1px"}}),_vm._v(" "),_c('div',{staticClass:"tag-added-container"},[_c('div',{staticClass:"tag-added-dropdown-wrapper",attrs:{"data-test-id":"tag-added-toggle"},on:{"click":_vm.toggleOptions}},[_c('ar-text',{staticClass:"tag-added-copy",attrs:{"size":"14px","text":_vm.messageListText,"multiple-lines":"","max-lines":1,"line-height":"20px"}}),_vm._v(" "),_c('ArrowHead',{staticClass:"tag-added-dropdown-arrowhead",class:_vm.listOpen && 'rotate',attrs:{"height":"8px","width":"10px","fill":"#8492a6"}})],1),_vm._v(" "),(_vm.listOpen)?_c('div',{staticClass:"tag-added-search-wrapper"},[_c('am2-search',{staticClass:"tag-added-search",attrs:{"placeholder":"Search tags...","data-test-id":"tag-added-search"},model:{value:(_vm.listSearchText),callback:function ($$v) {_vm.listSearchText=$$v},expression:"listSearchText"}}),_vm._v(" "),_c('div',{staticClass:"tags-list-wrapper"},[(_vm.noAvailableTag)?_c('a',{staticClass:"tag-link",on:{"click":_vm.handleTagAdd}},[_c('div',{staticClass:"item-wrapper"},[_c('ar-icon',{staticStyle:{"margin-right":"8px"},attrs:{"name":"circle-plus"}}),_vm._v(" "),_c('ar-text',{class:[
                'tag-name',
                'selected'
              ],style:({ marginRight: '4px' }),attrs:{"size":"xs","text":"Create tag","multiple-lines":"","max-lines":1,"line-height":"20px","weight":"normal"}}),_vm._v(" "),_c('ar-text',{class:[
                'tag-name',
                'selected'
              ],style:({ marginTop: '1px' }),attrs:{"size":"xs","text":("'" + _vm.listSearchText + "'"),"multiple-lines":"","max-lines":1,"line-height":"20px","weight":"bold"}})],1)]):_vm._e(),_vm._v(" "),_vm._l((_vm.tags),function(tag){return _c('div',{key:tag.oid,staticClass:"tag-link-wrapper"},[_c('a',{staticClass:"tag-link",attrs:{"data-test-id":("tag-added-anchor-" + (tag.oid))},on:{"click":function () { return _vm.setSelectedTag(tag); }}},[_c('div',{staticClass:"item-wrapper"},[_c('ar-text',{class:[
                  'tag-name',
                  _vm.selectedTag && tag.oid === _vm.selectedTag.oid && 'selected'
                ],attrs:{"size":"xs","text":tag.name,"multiple-lines":"","max-lines":1,"line-height":"20px","weight":_vm.selectedTag && tag.oid === _vm.selectedTag.oid ? 'bold' : 'normal'}})],1)])])})],2)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }