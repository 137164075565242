<template>
  <div
    class="sidebar-insights-wrapper"
  >
    <ar-snackbar
      v-if="lastExecutionWarningEnabled && status === 'started'"
      type="warning"
      message="No fans have entered your automation in over a week, please check your triggers if fans should be entering."
      class="u-margin-top-4"
    />

    <div v-if="showChartStats" class="sidebar-insights sidebar-automation-insights">
      <div class="insight-statistic" v-if="status === 'paused'">
        <div class="statistic-header">
          <ar-text
            size="16px"
            :text="`Not yet enrolled`"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-icon
            v-tooltip.bottom="{
              content: `Contacts who meet criteria but aren’t processed because the automation is paused.`,
            }"
            name="tooltip-question-mark"
            class="icon u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-text
          class="statistic"
          size="30px"
          :text="formatNumber(insightData.pending) || 0"
          multiple-lines
          line-height="34.5px"
        />
      </div>
      <!-- Temporarily removing in progress section as the calculations are not ready yet -->
      <!--
      <div class="insight-statistic" v-else>
        <div class="statistic-header">
          <ar-text
            size="16px"
            :text="`In progress`"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-icon
            v-tooltip.bottom="{
              content: `Fans that have met the criteria of a trigger and are currently progressing through your automation. This number will often be 0 for short automations.`,
            }"
            name="tooltip-question-mark"
            class="icon u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-text
          class="statistic"
          size="30px"
          :text="formatNumber(insightData.inProgress) || 0"
          multiple-lines
          line-height="34.5px"
        />
      </div>
      -->
      <div class="insight-statistic">
        <div class="statistic-header">
          <ar-text
            size="16px"
            :text="!!editableItem ? `Met criteria` : `Enrolled`"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-icon
            v-tooltip.bottom="{
              content: `Fans that have met the criteria of a trigger${!!editableItem ? ' and have completed the action steps' : ''}.`,
            }"
            name="tooltip-question-mark"
            class="icon u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-text
          class="statistic"
          size="30px"
          :text="formatNumber(insightData.complete) || 0"
          multiple-lines
          line-height="34.5px"
        />
      </div>
    </div>

    <div v-if="showMessageListSubscribedStats" class="sidebar-insights sidebar-automation-insights">
      <div class="insight-statistic">
        <div class="statistic-header">
          <ar-text
            size="16px"
            :text="`Subscribed`"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-icon
            v-tooltip.bottom="{
              content: `Fans that meet the trigger criteria and are subscribed to the message list`,
            }"
            name="tooltip-question-mark"
            class="icon u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-text
          class="statistic"
          size="30px"
          :text="formatNumber(automationBlockSubscribedFans) || 0"
          multiple-lines
          line-height="34.5px"
        />
      </div>
    </div>

    <div v-if="showMessageStats" class="sidebar-insights sidebar-message-insights">
      <div class="message-insight-header">
        <div>
          <ar-text
            class="header"
            size="16px"
            :text="messageInsightHeader"
            multiple-lines
            line-height="30px"
          />
          <!-- Only show the number of blocks if there are more than one -->
          <ar-text
            v-if="blockTotal && blockTotal > 1 && !editableItem"
            class="header-info"
            size="14px"
            :text="`${blockTotal} message blocks`"
            weight="normal"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
        </div>

        <messageTypeSelector
          v-if="messageTabEnabled"
          :selected="currentMessageType"
          @onSelect="handleMessageTypeSelection"
          :enable-all="false"
        />
      </div>

      <div
        v-if="currentMessageType === 'email' && !!messageStatsSource"
      >
        <div
          class="insight-statistic"
        >
          <gaugeCard
            :total="messageStatsSource.totalSent"
            :value="messageStatsSource.opened"
            :total-sent="messageStatsSource.totalSent"
            title="Open rate"
            :tooltip="`% of emails opened`"
            data-test-id="recipients-overview-open-rate"
          />
        </div>
        <div class="insight-statistic">
          <gaugeCard
            :total="messageStatsSource.totalSent"
            :value="messageStatsSource.clicked"
            :total-sent="messageStatsSource.totalSent"
            title="Click rate"
            :tooltip="`% of recipients who clicked on a link in the email`"
            data-test-id="recipients-overview-click-rate"
          />
        </div>
        <div class="insight-statistic">
          <gaugeCard
            :total="messageStatsSource.opened"
            :value="messageStatsSource.clicked"
            title="Click to open rate"
            :tooltip="`% of recipients who clicked a link in the email after opening it`"
            data-test-id="recipients-overview-ctor-rate"
            :enable-gauge="false"
            :enable-value="false"
          />
        </div>
      </div>
      <div
        v-if="currentMessageType === 'sms' && !!messageStatsSource"
      >
        <div class="insight-statistic">
          <gaugeCard
            :total="messageStatsSource.totalSent"
            :value="messageStatsSource.clicked"
            :total-sent="messageStatsSource.totalSent"
            title="Click rate"
            :tooltip="`% of recipients who clicked on a link in the sms`"
            data-test-id="recipients-overview-click-rate"
          />
        </div>
      </div>

    </div>



    <div v-if="showMessageStats" class="sidebar-insights sidebar-message-insights">
      <div class="message-insight-header">
        <ar-text
          class="header"
          size="16px"
          :text="`${messageTypeName} delivery summary`"
          multiple-lines
          line-height="30px"
        />
      </div>

      <div
        class="insight-statistic"
        v-if="!!messageStatsSource"
      >
        <gaugeCard
          :total="messageStatsSource.totalFinished"
          :value="messageStatsSource.totalSent"
          :total-sent="messageStatsSource.totalSent"
          title="Delivered"
          data-test-id="recipients-overview-delivered-rate"
        />
      </div>
      <div class="delivery-summary-info">
        <div class="delivery-summary-column">
          <div class="delivery-summary-card">
            <percentageCard
              :total="messageStatsSource.totalSent"
              :value="messageStatsSource.failed"
              title="Failed"
              data-test-id="recipients-overview-failed-rate"
            />
          </div>
          <div v-if="currentMessageType === 'email'" class="delivery-summary-card">
            <percentageCard
              :total="messageStatsSource.totalSent"
              :value="messageStatsSource.bounced"
              title="Bounced"
              data-test-id="recipients-overview-bounced-rate"
            />
          </div>
          <div class="delivery-summary-card">
            <percentageCard
              :total="messageStatsSource.totalSent"
              :value="messageStatsSource.unsubscribed"
              title="Unsubscribed"
              data-test-id="recipients-overview-unsubscribed-rate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import GaugeCard from './GaugeCard'
import PercentageCard from './PercentageCard'
import dayjs from 'dayjs';
import accounting from 'accounting';
import MessageTypeSelector from '@/pages/message-center/messages/components/message-type-selector.vue'

const ONE_WEEK = 7

export default {
  name: 'AutomationInsights',
  components: {
    gaugeCard: GaugeCard,
    messageTypeSelector: MessageTypeSelector,
    percentageCard: PercentageCard,
  },
  data() {
    return {
      currentMessageType: 'email',
      totalEmailBlocks: 0,
      totalSMSBlocks: 0,
    }
  },
  computed: {
    ...mapState({
      automationId: state => state.automation.id,
      automationInsights: state => state.automation.automationInsights,
      automationBlockSubscribedFans: state => state.automation.automationBlockSubscribedFans,
      editableItem: state => state.automation.editableItem,
      status: state => state.automation.status,
      auth: state => state.auth
    }),
    lastExecutionWarningEnabled() {
      // The automation hasn't been executed yet
      if (!this.automationInsights?.chartStats?.lastExecution) {
        return false;
      }

      const lastDateExecuted = dayjs(this.automationInsights?.chartStats?.lastExecution)
      const now = dayjs()

      // The automation hasn't been run for more than a week
      return now.diff(lastDateExecuted, 'day') > ONE_WEEK
    },
    insightData() {
      if (!this.automationInsights) {
        return null;
      }

      if (!!this.editableItem) {
        if (this.editableItem.type === 'action' || this.editableItem.type === 'trigger') {
          const blockStat = this.automationInsights.blockStats.find((blockStat) => {
            return blockStat.blockOid === this.editableItem.id &&
                ((this.editableItem.type.concat(".").concat(this.editableItem.key)) ===
                    (blockStat.blockType))
          })

          // If the block is either SMS or email, we need to put in stats focused on that block.
          if (!!blockStat?.messageStats && !!blockStat?.messageStats != {}) {
            const ctor = blockStat.messageStats.opened === 0 ? 0 : (blockStat.messageStats.clicked / (blockStat.messageStats.opened + blockStat.messageStats.clicked)) * 100;
            const totalFinished = blockStat.messageStats.totalFinished || blockStat.messageStats.totalProcessedMessages || (blockStat.messageStats.sent + blockStat.messageStats.clicked + blockStat.messageStats.opened)
            const totalSent = (totalFinished || 0) - (blockStat.messageStats.bounced || 0) - (blockStat.messageStats.failed || 0)
            const opened = (blockStat.messageStats.opened || 0) + (blockStat.messageStats.clicked || 0)

            const data = {
              ...blockStat,
              messageStats: {
                ...blockStat.messageStats,
                ctor: ctor,
                totalSent: totalSent,
                totalFinished: totalFinished,
                opened: opened
              }
            }

            return data;
          }
          else {
            return blockStat;
          }
        }
      }

      // CALCULATE OVERALL MESSAGE STATISTICS
      // Each category must be a summation of all the values present in the other blocks
      const overallMessageStatistics = this.extractOverallMessageStatics(this.automationInsights.blockStats)

      if (overallMessageStatistics.isMessageStatisticInBlocks && !this.editableItem) {
        return {
          ...this.automationInsights.chartStats,
          messageStats: overallMessageStatistics.statistics,
        }
      } else {
        return this.automationInsights.chartStats
      }
    },
    messageInsightHeader() {
      if (this.currentMessageType === 'email') {
        return 'Email engagement'
      } else if (this.currentMessageType === 'sms') {
        return 'SMS engagement'
      } else {
        return "Engagement"
      }
    },

    messageTypeName() {
      if (this.currentMessageType === 'email') {
        return 'Email'
      } else if (this.currentMessageType === 'sms') {
        return 'SMS'
      } else {
        return "Overall"
      }
    },

    messageTabEnabled() {
      if (!this.insightData || !this.insightData.messageStats || !!this.editableItem) {
        return false;
      }

      if (!this.showEmailTab && this.showSmsTab) {
        this.currentMessageType = 'sms'
      }

      if (!this.showSmsTab && this.showEmailTab) {
        this.currentMessageType = 'email'
      }

      return this.showEmailTab && this.showSmsTab
    },

    showEmailTab() {
      return this.insightData.messageStats.email.totalFinished > 0
    },

    showSmsTab() {
      return this.insightData.messageStats.sms.totalFinished > 0
    },

    messageStatsSource() {
      if (!this.editableItem || (this.editableItem && this.editableItem.key !== 'send.sms' && this.editableItem.key !== 'send.email')) {
        // Overall chart
        return this.insightData.messageStats[this.currentMessageType]
      } else {
        // Block
        return this.insightData.messageStats
      }
    },

    showMessageStats() {
      return !!this.insightData && !!this.insightData.messageStats && !!this.messageStatsSource
    },

    showChartStats() {
      return !!this.insightData && (!this.editableItem || (this.editableItem && this.editableItem.key !== 'send.sms' && this.editableItem.key !== 'send.email'))
    },

    showMessageListSubscribedStats() {
      return !this.showChartStats && !!this.editableItem && !!this.editableItem.config && !!this.editableItem.config['linked-message-list-oid'];
    },

    totalMessages() {
      return this.messageStatsSource.totalFinished - this.messageStatsSource.failed
    },

    blockTotal() {
      return this.currentMessageType === 'email' ? this.totalEmailBlocks : this.totalSMSBlocks
    },
  },
  watch: {
    showMessageListSubscribedStats(newVal, _oldVal) {
      if (newVal) {
        this.subscribedFans();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAutomationInsights: 'automation/FETCH_AUTOMATION_INSIGHTS',
      fetchAutomationBlockSubscribedFans: 'automation/FETCH_AUTOMATION_BLOCK_SUBSCRIBED_FANS',
    }),

    subscribedFans() {
      if (this.showMessageListSubscribedStats){
        this.fetchAutomationBlockSubscribedFans({
          chartOid: this.automationId || this.$route.query.oid,
          blockOid: this.editableItem.id
        });
      }
    },

    handleMessageTypeSelection(option) {
      this.currentMessageType = option;
    },

    formatNumber(number) {
      return accounting.formatNumber(number)
    },

    extractOverallMessageStatics(blockStats) {
      let isMessageStatisticInBlocks = false

      if (!blockStats) {
        return {
          isMessageStatisticInBlocks,
          statistics: null,
        }
      }

      let smsBlocks = 0
      let emailBlocks = 0

      const statistics = blockStats.reduce((overallStatistics, blockStat) => {
        if (!!blockStat.messageStats && Object.keys(blockStat.messageStats).length > 0) {
          isMessageStatisticInBlocks = true

          let messageType = null;
          if (blockStat.blockType === 'action.send.sms') {
            messageType = 'sms'
            smsBlocks++
          } else if (blockStat.blockType === 'action.send.email') {
            messageType = 'email'
            emailBlocks++
          }


          if (!!messageType) {
            const totalFinished = blockStat.messageStats.totalFinished || blockStat.messageStats.totalProcessedMessages || (blockStat.messageStats.sent + blockStat.messageStats.clicked + blockStat.messageStats.opened)

            const totalSent = (totalFinished || 0) - (blockStat.messageStats.bounced || 0) - (blockStat.messageStats.failed || 0)

            overallStatistics[messageType] = {
              totalFinished: overallStatistics[messageType].totalFinished + totalFinished - (overallStatistics[messageType].failed + (blockStat.messageStats.failed || 0)),
              sent: overallStatistics[messageType].sent + (blockStat.messageStats.sent || 0),
              opened: overallStatistics[messageType].opened + (blockStat.messageStats.opened || 0) + overallStatistics[messageType].clicked + (blockStat.messageStats.clicked || 0),
              clicked: overallStatistics[messageType].clicked + (blockStat.messageStats.clicked || 0),
              unsubscribed: overallStatistics[messageType].unsubscribed + (blockStat.messageStats.unsubscribed || 0),
              bounced: overallStatistics[messageType].bounced + (blockStat.messageStats.bounced || 0),
              failed: overallStatistics[messageType].failed + (blockStat.messageStats.failed || 0),
              totalSent: overallStatistics[messageType].totalSent + totalSent,
            }
          }
        }

        return overallStatistics
      }, {
        sms: {
          totalFinished: 0,
          sent: 0,
          opened: 0,
          clicked: 0,
          unsubscribed: 0,
          bounced: 0,
          failed: 0,
          ctor: 0,
          totalSent: 0,
        },
        email: {
          totalFinished: 0,
          sent: 0,
          opened: 0,
          clicked: 0,
          unsubscribed: 0,
          bounced: 0,
          failed: 0,
          ctor: 0,
          totalSent: 0,
        }
      })

      this.totalEmailBlocks = emailBlocks
      this.totalSMSBlocks = smsBlocks

      return {
        isMessageStatisticInBlocks,
        statistics,
      }
    }
  },
  mounted() {
      if(this.automationId || this.$route.query.oid) {
          this.fetchAutomationInsights({automationOid: this.automationId || this.$route.query.oid})
      }
  },
}
</script>
<style lang="scss" scoped>

.sidebar-insights-wrapper {
  margin-top: 24px;
  max-height: 100%;
  overflow-y: auto;

  .sidebar-insights {
    margin-top: 24px;
    border: 1px solid $skyBlueGrey500;
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
    width: 100%;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 24px;
    }
  }

  .sidebar-automation-insights {
    .insight-statistic {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid $skyBlueGrey400;
      padding: 24px 0 24px 0;

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        border-bottom: 0px;
        padding-bottom: 0px;
      }

      &:only-child {
        padding-top: 0px;
      }

      .statistic-header {
        display: flex;
        flex-direction: row;
      }

      .statistic {
      }
    }
  }

  .sidebar-message-insights {
    padding: 20px 20px 20px 20px;
    .message-insight-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 16px;
    }


    .insight-statistic {
      border-bottom: 1px solid $skyBlueGrey400;
      padding: 12px 0 24px 0;

      &:last-child {
        border-bottom: 0px;
      }
    }

    .delivery-summary-info {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .delivery-summary-column {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 12px;

        .delivery-summary-card {
          width: calc(50% - 6px);
          flex-grow: 1 0 50%;
          border-right: 1px solid $skyBlueGrey400;

          &:nth-child(2n), &:last-child {
            border-right: 0px;
          }
        }

      }
    }
  }
}
</style>
