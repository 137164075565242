<template>
  <EditableFrame
    class="event-date-trigger-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    :block-type="item.type"
    @close="$emit('close')"
    @save="handleSave"
    @click.native="handleClickOutside"
  >
    <ar-text
      class="to-events-heading"
      size="14px"
      text="To event"
      weight="bold"
      multiple-lines
      :max-lines="1"
    />
    <div class="event-date-trigger-container">
      <div class="event-date-trigger-err-dropdown" @click="toggleOptions">
        <div class="event-date-trigger-dropdown-wrapper">
          <ar-text
            class="event-date-trigger-copy"
            size="14px"
            :text="selectedEventCopy"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
          <ArrowHead
            class="event-date-trigger-dropdown-arrowhead"
            :class="listOpen && 'rotate'"
            height="8px"
            width="10px"
            :fill="$arStyle.color.skyBlueGrey700"
          />
        </div>
        <ar-state-message
          v-if="validationError.eventSelection"
          :text="validationError.eventSelection"
          type="error"
        />
      </div>
      <div v-if="listOpen" class="event-date-trigger-search-wrapper">
        <am2-search
          class="event-date-trigger-search"
          v-model="listSearchText"
          placeholder="Search events..."
        />
        <div class="event-date-trigger-options-wrapper">
          <div
            class="options-list"
          >
            <div v-if="noMatchingEvent" class="event-link no-event-found-wrapper">
              <div class="item-wrapper">
                <ar-text
                  class="item-name"
                  :style="{ marginRight: '4px' }"
                  size="xs"
                  text="No matching event found"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  weight="normal"
                />
              </div>
            </div>
            <div
              v-for="event in displayedEvents"
              :key="event.oid"
              class="checkbox-section"
            >
              <ar-checkbox
                :ref="`event-date-trigger-checkbox-${event.oid}`"
                @change="(value) => handleCheckboxClick(event, value)"
                class="event-date-trigger-checkbox"
                :value="isChecked(event.oid)"
              />
              <div class="checkbox-label-section" @click="handleLableClick(event.oid)">
                <div class="checkbox-label-first-line">
                  <ar-text
                    size="xs"
                    v-bind:text="event.name || 'Has been removed'"
                    v-tooltip.top="(event.name || 'Has been removed').length > 26 ? {
                    content: event.name || 'Has been removed' | capitalize
                  } : null"
                    :style="{
                      width: 'calc(100% - 60px)',
                      color: !event.name ? $arStyle.color.red500 : null,
                      margin: '0 8px 4px 0',
                    }"
                  />
                </div>
                <ar-text
                  v-if="!!event.location"
                  id="filter-purchased-event-location"
                  size="xxs"
                  :text="event.location"
                  :style="{
                    color: $arStyle.color.blueGrey600,
                    marginBottom: '2px',
                  }"
                />
                <ar-text
                  size="xxxs"
                  :text="event.datestr"
                  :style="{
                    color: $arStyle.color.blueGrey600,
                  }"
                />
            </div>
            </div>
          </div>
          <div class="actions-bar">
            <div>
              <span
                :class="['deselect-all',
                          this.selectedEvents.length && 'active']"
                @click="deselectAll()"
              >
                Deselect All
              </span>
            </div>

            <ar-simple-button
              text="Apply"
              :style="{
                height: '40px',
              }"
              @click="handleCloseList"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
          <ar-text class="form-row__label" text="When will this trigger activate?" weight="bold" size="xs" />
          <ar-simple-select
            :items="executionTimeTypeOptions"
            :default-select-index="executionTimeTypeSelectIndex"
            @select="handleSelectBeforeAfter"
          />
      </div>
      <div class="form-row" v-if="executionTimeType && executionTimeType !== 'dayofe'">
          <ar-text class="form-row__label" text="How many days before/after?" weight="bold" size="xs" />
          <ar-simple-select
            :items="executionDays"
            :default-select-index="triggerDaysIndex"
            @select="handleTriggerDaysChange"
          />
      </div>
      <div class="form-row time-timezone-selection" v-if="executionTimeType">
        <ar-text class="form-row__label" text="What time?" weight="bold" size="xs" />
        <div class="selection-row">
          <ar-input
            placeholder="12:00pm"
            :value="triggerTime"
            :style="{
              height: '50px',
              marginRight: '20px',
              width: '30%'
            }"
            autocomplete="time"
            @input="handleTimeChange"
            @blur="handleTimeInputBlur"
          />
          <am2-timezone-select
            v-model="triggerTimezone"
            placeholder="Search for a timezone"
            class="form-row__input"
            :class="{'form-row__input--placeholder': !triggerTimezone}"
            @input="handleSelectTimezone"
          />
        </div>
    </div>
      <ar-state-message
        v-if="validationError.typeDaySelection"
        :text="validationError.typeDaySelection"
        type="error"
      />
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
export default {
  name: 'EventDateTrigger',
  components: {
    EditableFrame,
    ArrowHead,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      listOpen: false,
      listSearchText: '',
      selectedEvents: [],
      filteredEventsList: [],
      initialEventsList: [],
      executionTimeTypeOptions: [
          {
              name: 'Before the event date',
              key: 'before'
          },
          {
              name: 'After the event date',
              key: 'after'
          },
          {
              name: 'Day of the event',
              key: 'dayofe'
          }
      ],
      executionTimeType: null,
      triggerDays: null,
      validationError: {},
      localTimezone: null,
      triggerTime: null,
      triggerTimezone: null,
      executionOptions: [
        {name: '1 day', key: 1},
        {name: '2 days', key: 2},
        {name: '3 days', key: 3},
        {name: '4 days', key: 4},
        {name: '5 days', key: 5},
        {name: '6 days', key: 6},
        {name: '1 week', key: 7},
        {name: '2 weeks', key: 14},
        {name: '1 month', key: 30}
      ]
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    listSearchText() {
      this.searchEventsOnBackend(this.listSearchText)
    }
  },
  computed: {
    noMatchingEvent() {
      return !this.filteredEventsList?.length
    },
    savedButtonDisabled() {
      return !this.executionTimeType
      || (this.executionTimeType !== 'dayofe' && !this.triggerDays)
      || !this.triggerTime
      || !this.triggerTimezone
      || Object.keys(this.validationError).length !== 0
    },
    selectedEventCopy() {
      switch(this.selectedEvents?.length){
        case 0:
          return !!this.executionTimeType ? "Any events" : "Select Events"
        case 1:
          return this.selectedEvents[0].name
        default:
          return this.selectedEvents?.length + " events selected"
      }
    },
    displayedEvents() {
      const unselectedEvents = this.filteredEventsList.filter(item => {
        if (this.selectedEvents?.length){
          return !this.selectedEvents?.find(e => e.oid === item.oid)
        }else {
          return true
        }
      });
      return this.selectedEvents.concat(unselectedEvents);
    },
    executionDays() {
      return this.executionOptions.map(i => {
        return {
          key: i.key,
          name: i.name + ' ' + this.executionTimeType
        }
      })
    },
    executionTimeTypeSelectIndex() {
      return this.executionTimeTypeOptions.findIndex(({ key }) => {
        return key === this.executionTimeType
      });
    },
    triggerDaysIndex(){
      return this.executionDays.findIndex(({ key }) => key === this.triggerDays);
    },
  },
  methods: {
    async searchEventsOnBackend(text) {
      let { rows } = await this.fetchEvents({ orderBy: 'datecreated', top: '200', selectString: 'name,location,startDate,endDate,timeZone', searchString: text });

      this.filteredEventsList = rows.map(item => {
        const startDate = item.startDate ? dayjs.utc(item.startDate).tz(this.localTimezone) : dayjs.utc().tz(this.localTimezone);
        const endDate = item.endDate ? dayjs.utc(item.endDate).tz(this.localTimezone) : null
        return {
          oid: item.oid,
          name: item.name,
          location: item.location,
          startDate: startDate,
          endDate: endDate,
          timezone: this.localTimezone,
          datestr: this.getDateString(startDate, endDate? endDate: startDate),
        }
      });
    },
    ...mapActions({
      fetchEvents: 'FETCH_EVENTS',
      fetchTicketClasses: 'automation/FETCH_TICKET_CLASSES',
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleLableClick(oid) {
      this.$refs[`event-date-trigger-checkbox-${oid}`][0].toggle()
    },
    handleCheckboxClick(event, checked) {
      if (checked) {
        this.selectedEvents.push(event);
      } else {
        this.selectedEvents = this.selectedEvents?.filter(item => item.oid !== event.oid)
      }
      this.validateEventSelection();
      this.validateTypeDaySelection();
    },
    isChecked(oid) {
      return this.selectedEvents?.findIndex(i => i.oid === oid) > -1
    },
    handleSelectBeforeAfter(e){
      this.executionTimeType = e.key
      this.validateTypeDaySelection();
    },
    handleTriggerDaysChange(e) {
      this.triggerDays = e.key
      this.validateTypeDaySelection();
    },
    handleClickOutside(e) {
      if (!this.listOpen) return

      let clickOutsideClasses = ['content-wrapper', 'header-wrapper', 'heading-close-icon-wrapper', 'footer-wrapper', 'event-date-trigger-container']
      if (clickOutsideClasses.includes(e.target.className)) {
        this.handleCloseList();
      }
    },
    handleCloseList(){
      this.listOpen = false
      this.listSearchText = ''
    },
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    handleSave() {
      this.updateTriggerContextData({
        id: this.item.id,
        data: {
          config: {
            events: this.selectedEvents?.map(item => item.oid),
            type: this.executionTimeType,
            days: this.triggerDays,
            cached: this.selectedEvents,
            time: this.triggerTime,
            timezone: this.triggerTimezone
          }
        }
      });
      this.$emit('close');
    },
    async fetchDropdownEvents() {
      let { rows } = await this.fetchEvents({ orderBy: 'datecreated', top: '200', selectString: 'name,location,startDate,endDate,timeZone' })

      let events = rows.map(item => {
        const startDate = item.startDate ? dayjs.utc(item.startDate).tz(this.localTimezone) : dayjs.utc().tz(this.localTimezone);
        const endDate = item.endDate ? dayjs.utc(item.endDate).tz(this.localTimezone) : null;
        return {
          oid: item.oid,
          name: item.name,
          location: item.location,
          startDate: startDate,
          endDate: endDate,
          timezone: this.localTimezone,
          datestr: this.getDateString(startDate, endDate? endDate: startDate)
        }
      })
      this.initialEventsList = events
      this.filteredEventsList = events
    },
    deselectAll(){
      this.selectedEvents = []
      this.validateEventSelection();
    },
    handleTimeChange(time) {
      this.triggerTime = time;
    },
    handleTimeInputBlur() {
      if (!this.triggerTime) {
        return;
      }

      this.triggerTime = dayjs(this.triggerTime, 'hh:mma').format('hh:mma');
      if (this.triggerTime === 'Invalid Date') {
        this.triggerTime = dayjs().format('hh:mma');
      }
      this.validateTypeDaySelection()
    },
    handleSelectTimezone (tz){
      this.triggerTimezone = tz
      this.validateTypeDaySelection();
    },
    resetDefaults() {
      this.selectedEvents = []
      this.listOpen = false
      this.listSearchText = ''
    },
    async setItemVals() {
      this.resetDefaults()
      this.localTimezone = dayjs.tz.guess();
      if (!this.initialEventsList.length) await this.fetchDropdownEvents();

      if (this.item.config?.['events']?.length) {
        this.selectedEvents = this.initialEventsList.filter(e => {
          return this.item?.config?.['events'].find(oid => oid === e.oid)
        });
      }

      this.triggerDays = this.item.config?.['days'];
      this.executionTimeType = this.item.config?.['type'];
      this.triggerTime = this.item.config?.['time'];
      this.triggerTimezone = this.item.config?.['timezone'];

    },
    getDateString(startDate, endDate) {
      if (!startDate || !endDate) {
          return null;
      }

      const isMultiDay = startDate.day() !== endDate.day();
      const isMultiYear = startDate.year() !== endDate.year();

      const startDateMinutes = startDate.format('mm');
      const endDateMinutes = endDate.format('mm');
      let startTimeFormat;
      if (startDateMinutes === '00') {
          startTimeFormat = 'ha';
      } else {
          startTimeFormat = 'h:mma'
      }
      let endTimeFormat;
      if (endDateMinutes === '00') {
          endTimeFormat = 'ha';
      } else {
          endTimeFormat = 'h:mma'
      }

      if (isMultiDay && !isMultiYear) {
          return (
          startDate.format(`D MMM, ${startTimeFormat}`) +
          " — " +
          endDate.format(`D MMM YYYY, ${endTimeFormat}`)
          );
      } else if (isMultiYear) {
          return (
          startDate.format(`D MMM YYYY, ${startTimeFormat}`) +
          " — " +
          endDate.format(`D MMM YYYY, ${endTimeFormat}`)
          );
      }
      return startDate.format(`D MMM YYYY, ${startTimeFormat}`);
    },
    validateEventSelection(){
      let now = dayjs.utc().tz(this.localTimezone);

      const error = this.selectedEvents.filter(event => {
        const maxEventDate = (event.endDate? event.endDate: event.startDate).clone().add(30, "days");
        const minEventDate = event.startDate.clone().subtract(30, "days");

        return (maxEventDate.diff(now, "minutes") < 0)  && (minEventDate.diff(now, "minutes") <0);
      });

      if (error.length) {
        this.$set(this.validationError, 'eventSelection',"This/These event trigger options are all in the past, please select another event")
      } else {
        this.$delete(this.validationError, 'eventSelection');
      }
    },
    // convert eventdate to target tz
    // get the day
    // add time and build the datetime object for exact trigger time
    getTriggerDt(dt, time, tz){
      return dayjs.tz(
        dt.clone().tz(tz).format("YYYY-MM-DD")
        + ' '
        + time, 'YYYY-MM-DD hh:mma', tz)
    },
    validateTypeDaySelection(){
      let type = this.executionTimeType
      let days = this.triggerDays
      let time = this.triggerTime
      let tz = this.triggerTimezone

      if (!type || (!days && type !== 'dayofe') || !time || !tz) {
        return;
      }

      const error = this.selectedEvents.filter(event => {
        let now = dayjs.utc().tz(tz);
        if (type === 'dayofe'){
          return this.getTriggerDt(event.startDate, time, tz).diff(now, "minutes") < 0
        }

        if (type === 'before' && days){
          const minDate = event.startDate.clone().subtract(days, "days");
          return this.getTriggerDt(minDate, time, tz).diff(now, "minutes") < 0
        }

        if (type === 'after' && days) {
          const maxDate = (event.endDate? event.endDate: event.startDate).clone().add(days, "days")
          return this.getTriggerDt(maxDate, time, tz).diff(now, "minutes") < 0
        }
      });

      if (error.length) {
        this.$set(this.validationError, 'typeDaySelection', "This date has already passed, please select another option");
      } else {
        this.$delete(this.validationError, 'typeDaySelection');
      }
    }
  },
  mounted() {
    this.setItemVals()
  }
}

</script>
<style lang="scss" scoped>
.event-date-trigger-wrapper {
  width: 100%;
  height: 100%;

  .error-string {
    color: $red500;
    line-height: normal;
  }

  .to-events-heading {
    margin-bottom: 8px;
    pointer-events: none;
  }

  .event-date-trigger-container {
    .event-date-trigger-err-dropdown {
      margin-bottom: 20px;
    }
    .event-date-trigger-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(14) ui-px2em(18) ui-px2em(16) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      width: 100%;
      min-height: 52px;
      cursor: pointer;

      .event-date-trigger-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }
    .event-date-trigger-search-wrapper {
      width: calc(100% - 45px);
      position: absolute;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      z-index: 10;
      margin-bottom: 10px;
      margin-top: -15px;

      .event-date-trigger-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
        margin-bottom: 17px;
      }

      .event-date-trigger-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 360px;
        overflow: auto;
        padding-bottom: 10px;

        .event-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          .item-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            width: 100%;
            padding: 9px;

            .item-name {
              &.selected {
                color: $purple500;
              }
            }

            .event-date-trigger-date-string, .event-date-trigger-location {
              color: #c1c6cf;

              &.selected {
                color: #ad8adf;
              }
            }
          }
        }
      }
    }

    .form-row {
      margin-bottom: 20px;

      .ar-text {
          margin-bottom: 14px;
      }
    }

    .time-timezone-selection {
      .selection-row {
        display: flex;

        .ar-timezone-select {
          width: 70%;
        }
      }
    }
  }

  .event-date-trigger-type-container {
    .event-date-trigger-type-title {
      font-weight: 500;
      margin-top: 32px;
    }
    .event-date-trigger-type-subtitle {

      margin-top: 12px;
      color: $blueGrey700;
    }
    .event-date-trigger-type-checkbox {
      margin-top: 16px;
    }
  }
}

.event-date-trigger-options-wrapper {
  width: 100%;
  padding: 0 10px 10px 10px;

  .options-list {
    height: 360px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .actions-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid $blueGrey500;
  }

  .no-event-found-wrapper {
      margin-bottom: 10px;
  }

  .option-heading {
    margin-bottom: 14px;
  }

  .options-heading {
    margin-top: 52px;
    margin-bottom: 14px;
  }

  .checkbox-section {
      display: flex;
      margin-bottom: 9px;
      align-items: flex-start;

      .checkbox {
        width: 20px;
      }

      .checkbox-label-section {
        cursor: pointer;
        width: calc(100% - 28px);
        margin-left: 8px;
        .checkbox-label-first-line {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  .deselect-all {
    display: inline-block;
    margin-top: 7px;
    font-size: 12px;
    line-height: 1em;
    color: $blueGrey700;
    letter-spacing: 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .active {
    color: $purple500
  }

}
</style>
