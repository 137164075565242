var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.startLoading && !_vm.isAutomationCreating)?_c('div',{staticClass:"automation-sidebar-element-wrapper"},[_c('div',{staticClass:"sidebar-header-wrapper"},[_c('ar-text',{attrs:{"size":"20px","text":_vm.headerText,"weight":"bold","multiple-lines":"","line-height":"24px"}}),_vm._v(" "),_c('ar-text',{staticClass:"sidebar-header-info",attrs:{"size":"14px","text":_vm.sidebarHeaderInfo,"weight":"normal","multiple-lines":""}}),_vm._v(" "),_c('div',{staticClass:"sidebar-action-wrapper"},[_c('ar-simple-button',{style:({
            height: '32px',
          }),attrs:{"text":_vm.actionButton,"loading":!_vm.actionButton},on:{"click":function () { return _vm.handleOptionSelect(_vm.actionAutomationSettings); }}}),_vm._v(" "),(_vm.status != 'new' && _vm.promoterIsInAutomationWhitelist)?_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: _vm.isEditButtonVisible && _vm.isEditDisabled ? 'Automations must be paused or stopped in order to edit a trigger or action' : null,
          }),expression:"{\n            content: isEditButtonVisible && isEditDisabled ? 'Automations must be paused or stopped in order to edit a trigger or action' : null,\n          }",modifiers:{"bottom":true}}],style:({
            height: '32px',
          }),attrs:{"text":_vm.isEditButtonVisible ? 'Edit' : 'Insights',"outlined":"","disabled":_vm.isEditButtonVisible && _vm.isEditDisabled},on:{"click":_vm.handleEditButton}}):_vm._e(),_vm._v(" "),_c('am2-icon-button-dropdown',{staticClass:"settings-dropdown",attrs:{"items":_vm.settingsItemsFiltered,"icon-props":{
            name: 'menu',
          }},on:{"select":function (item) { return _vm.handleOptionSelect(item); }}})],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditEnabled || _vm.status === 'new' || !_vm.promoterIsInAutomationWhitelist),expression:"isEditEnabled || status === 'new' || !promoterIsInAutomationWhitelist"}],staticClass:"sidebar-editable-section"},[_c('div',{staticClass:"sidebar-tabs-wrapper"},[_c('am2-elegant-tabs',{staticClass:"tabs",attrs:{"layout":"wide","items":_vm.blockTypes,"tab-key":_vm.selectedSidebarTab},on:{"select":_vm.selectSidebarTab}}),_vm._v(" "),_c('am2-search',{ref:"timzoneSearchInput",staticClass:"sidebar-search",attrs:{"tabindex":"0","placeholder":("Search " + _vm.selectedSidebarTab),"data-test-id":"sidebar-search-text"},model:{value:(_vm.sidebarSearchText),callback:function ($$v) {_vm.sidebarSearchText=$$v},expression:"sidebarSearchText"}})],1),_vm._v(" "),_c('div',{staticClass:"sidebar-draggable-wrapper"},[_c(_vm.selectedSidebarTab,{tag:"component",attrs:{"options":_vm.filteredSidebarOptions}})],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.isEditEnabled || _vm.status === 'new') && _vm.promoterIsInAutomationWhitelist),expression:"!(isEditEnabled || status === 'new') && promoterIsInAutomationWhitelist"}],staticClass:"sidebar-insights-section"},[(!!_vm.getLastUpdateTime())?_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":_vm.getLastUpdateTime(),"multiple-lines":""}}):_vm._e(),_vm._v(" "),_c('automationInsights')],1)]):_c('div',{staticClass:"loading automation-sidebar-element-wrapper"},[_c('am2-loading-bubble'),_vm._v(" "),_c('ar-text',{attrs:{"size":"20px","text":_vm.loadingText,"multiple-lines":"","line-height":"24px"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }