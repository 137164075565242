<template>
  <div class="triggers-planner-wrapper">
    <draggable
      id="triggers-draggable"
      class="triggers-draggable-wrapper"
      group="triggersDraggable"
      @start="() => setTriggerIsMoving(true)"
      @end="() => setTriggerIsMoving(false)"
      :list="triggersLocal"
      @add="handleAdd"
    >
      <div
        v-for="(trigger, index) in triggers"
        :key="trigger.id"
        :id="trigger.id"
        class="trigger-item-wrapper"
        :class="[
          isActiveElement(trigger.id) ? 'active' : '',
          `leader-self-${rootBoxId}`,
          getBlockStatus(trigger.id),
        ]"
        :data-key="trigger.key"
        @click="(event) => handleClick(trigger.id, event)"
      >
        <div v-if="isEditEnabled" :id="`trigger-close-${trigger.id}`" class="trigger-close-icon-wrapper" @click="handleDelete(trigger.id, index)">
          <CloseCrossIcon class="trigger-close-icon"/>
        </div>
        <div class="planner-item-container">
          <div class="auto-card-heading">
            <div
              v-html="require(`~/assets/icons/${trigger.icon}.svg?raw`)"
              :class="`auto-card-icon ${trigger.icon}-icon`"
            />
            <ar-text
              class="auto-item-text"
              size="14px"
              :text="triggerName(trigger)"
              weight="bold"
              multiple-lines
              :max-lines="1"
              line-height="2.14"
            />
          </div>

          <div class="card-sub-wrapper">
            <component
              :is="cardType(trigger)"
              :item="trigger"
            />
          </div>
        </div>

        <div class="card-placeholder-wrapper">
          <ar-text
            class="card-placeholder-text"
            size="14px"
            text="Drag a new trigger here"
            weight="normal"
            multiple-lines
            :max-lines="1"
            :style="{
              opacity: '0.8',
            }"
          />
        </div>
        <template v-if="index === triggers.length - 1">
          <pre :key="`${setTriggersRenderComplete(index)}`" style="display: none;"></pre>
        </template>
      </div>
    </draggable>
    <div v-if="!loading && !triggers.length" class="intro-placeholder-wrapper">
      <ar-text
        class="intro-item-placeholder"
        size="14px"
        text="Drag a Trigger to get started."
        weight="normal"
        multiple-lines
        :max-lines="1"
        line-height="2.14"
      />
    </div>
  </div>
</template>
<script>
import { automationCardType } from '../../utils'
import { mapState, mapGetters, mapActions } from 'vuex'
import PurchasedTicket from '../AutomationPlanner/PurchasedTicket'
import PurchasedProduct from '../AutomationPlanner/PurchasedProduct'
import RegistersForCampaign from '../AutomationPlanner/RegistersForCampaign'
import TriggerTagAdded from '../AutomationPlanner/TriggerTagAdded'
import TriggerTagRemoved from '../AutomationPlanner/TriggerTagRemoved'
import TriggerWebhook from '../AutomationPlanner/TriggerWebhook'
import ListSubscribed from '../AutomationPlanner/ListSubscribed'
import ListUnsubscribed from '../AutomationPlanner/ListUnsubscribed'
import ShortURLVisited from '../AutomationPlanner/ShortURLVisited'
import Birthday from '../AutomationPlanner/TriggerBirthday'
import EventDate from '../AutomationPlanner/EventDate'
import CloseCrossIcon from '~/assets/icons/close-cross-icon.svg?inline'
export default {
  name: 'DraggableTriggers',
  components: {
    purchasedTicket: PurchasedTicket,
    purchasedProduct: PurchasedProduct,
    registersForCampaign: RegistersForCampaign,
    triggerTagAdded: TriggerTagAdded,
    triggerTagRemoved: TriggerTagRemoved,
    triggerWebhook: TriggerWebhook,
    listSubscribed: ListSubscribed,
    listUnsubscribed: ListUnsubscribed,
    shortUrlVisited: ShortURLVisited,
    triggerBirthday: Birthday,
    CloseCrossIcon,
    eventDate: EventDate
  },
  props: {
    triggerErrors: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      triggersLocal: [],
      executionOptions: [
        {name: '1 day', key: 1},
        {name: '2 days', key: 2},
        {name: '3 days', key: 3},
        {name: '4 days', key: 4},
        {name: '5 days', key: 5},
        {name: '6 days', key: 6},
        {name: '1 week', key: 7},
        {name: '2 weeks', key: 14},
        {name: '1 month', key: 30}
      ]
    }
  },
  computed: {
    ...mapState({
      triggers: state => state.automation.triggers.items,
      rootBoxId: state => state.automation.rootBoxId,
      loading: state => state.automation.loading,
      status: state => state.automation.status,
      isEditEnabled: state => state.automation.isEditEnabled,
    }),
    ...mapGetters({
      editableId: 'automation/getEditableId',
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    isAutomationFeatureEnabled() {
      return this.isFeatureEnabled(['automation']);
    },
  },
  methods: {
    ...mapActions({
      findAndSetEditableTrigger: 'automation/FIND_AND_SET_EDITABLE_TRIGGER',
      addTrigger: 'automation/ADD_TRIGGER',
      deleteTrigger: 'automation/DELETE_TRIGGER',
      setIsTriggerMoving: 'automation/SET_TRIGGER_IS_MOVING',
    }),
    setTriggerIsMoving(bool) {
      this.setIsTriggerMoving(bool)
    },
    handleDelete(id, index) {
      if (!this.status) {
        return;
      }

      if (this.status.includes('started')) {
        this.$arNotification.push({ type: 'error', message: `Please change status of Automation before modifying.` })
        return
      }
      if (this.status.includes('ing')|| this.status.includes('should')) {
        this.$arNotification.push({ type: 'error', message: 'Please wait for status to update before modifying Automation' })
        return
      }
      if (this.triggers.length === 1) {
        this.$arNotification.push({ type: 'error', message: `You must have atleast 1 trigger.` })
        return
      }

      this.deleteTrigger({id, index})
    },
    handleAdd(event) {
      let unallowedDuplicateKeys = ['contact.birthday']

      if (!this.status) {
        return;
      }

      if (this.status.includes('started')) {
        this.$arNotification.push({ type: 'error', message: `Please change status of Automation before modifying.` })
        return
      }
      if (this.status.includes('ing')|| this.status.includes('should')) {
        this.$arNotification.push({ type: 'error', message: 'Please wait for status to update before modifying Automation' })
        return
      }
      let item = event.item._underlying_vm_
      if (this.hasUnallowedDuplicateTriggers(item.key, this.triggers, unallowedDuplicateKeys)) {
        this.$arNotification.push({ type: 'error', message: `You only need one ${item.name} trigger.` })
      } else {
        this.addTrigger(item)
      }
    },
    hasUnallowedDuplicateTriggers(incomingKey, triggers, unallowedDuplicateKeys) {
      // checking that the incoming trigger type is
      // one that can only appear once (eg Birthday),
      // and whether the current triggers list already has
      // on of these trigger types.
      return unallowedDuplicateKeys.includes(incomingKey) && triggers.some(trigger => trigger.key === incomingKey)
    },
    isActiveElement(id) {
      return this.editableId === id
    },
    handleClick(id, event) {
      if (event.target.className === 'trigger-close-icon-wrapper') return
      this.findAndSetEditableTrigger(id)
    },
    capitalise(text) {
      return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
    },
    numberToString(n) {
      switch (n) {
      case '1':
        return 'One';
      case '2':
        return 'Two';
      case '3':
        return 'Three';
      case '4':
        return 'Four';
      case '5':
        return 'Five';
      case '6':
        return 'Six';
      default:
        return n;
      }
    },
    triggerName(item) {
      const itemKey = item?.key;
      let tName = item?.name;
      if (itemKey === 'contact.birthday' && !!item.config) {
        const itemConfig = item.config;
        const timeIsSet = itemConfig.time !== undefined && itemConfig.time !== null;
        const whenIsSet = itemConfig.when !== undefined && itemConfig.when !== null;

        if (timeIsSet) {
          const [number, unit, when] = itemConfig?.time.split("_");
          tName = this.numberToString(number) + " " + this.capitalise(unit) + " " + this.capitalise(when) + " Birthday";
        }
        if (!timeIsSet && whenIsSet) {
          const isDayOf = itemConfig.when === 'DAY_OF_BIRTHDAY'
          const [when, bdayString] = itemConfig?.when.split("_");
          tName = this.capitalise(when) + " " + this.capitalise(bdayString) + (isDayOf ? " Birthday" : "");
        }
        return tName;
      } else if (itemKey === 'event-date' && item?.config?.days && item?.config?.type) {
        const days = this.executionOptions.find(i => i.key === item.config.days).name
        return days + ' ' + item.config.type + ' ' + 'event date'
      } else {
        return tName;
      }
    },
    cardType(item) {
      return automationCardType(item)
    },
    setTriggersRenderComplete() {
      this.$nextTick(() => {
        this.$emit('rendered')
      })
    },

    getBlockStatus(id) {
      if (this.triggerErrors.includes(id)) {
        return 'block-error border-red'
      } else {
        return 'border-purple'
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  $autoItemWidth: 325px;
  @mixin flexRow {
    display: flex;
    flex-flow: row nowrap;
  }

  .triggers-planner-wrapper {
    min-width: 100%;
    @include flexRow;
    align-items: center;
    justify-content: center;
    position: relative;

    .triggers-draggable-wrapper {
      width: 100%;
      padding: 0 15px;
      position: relative;
      @include flexRow;
      align-items: flex-end;
      justify-content: center;
      min-height: 100px;
      margin-top: 20px;
      transform-origin: bottom;

      .trigger-item-wrapper {
        border-radius: 3px;
        border: solid 0.2px rgba(194, 194, 194, 0.3);
        background-color: white;
        padding: 10px 20px;
        width: $autoItemWidth;
        margin-left: 9px;
        margin-right: 9px;
        cursor: pointer;
        position: relative;

        &:hover {
          .trigger-close-icon-wrapper {
            display: flex;
          }
        }

        &.active {
          border: 1px solid $green500;
          box-shadow: 0 0 0 3px $green200;

          &.block-error {
            box-shadow: 0 0 0 3px $red200;
          }
        }

        &.block-error {
          border: 1px solid $red500;
        }

        &.border-purple {
          border-left: 6px solid $purple500;
        }

        &.border-red {
          border-left: 6px solid $red500;
        }

        &.sortable-ghost {
          border-left: unset;
          height: 50px;
          background: none;

          &.active {
            border-right: solid 0.2px rgba(194, 194, 194, 0.3);
            border-top: solid 0.2px rgba(194, 194, 194, 0.3);
            border-bottom: solid 0.2px rgba(194, 194, 194, 0.3);
            box-shadow: unset;
          }

          .planner-item-container {
            display: none;
          }

          .card-placeholder-wrapper {
            display: flex;
            border-left: solid 0.2px rgba(194, 194, 194, 1) !important;
            opacity: 0.3;
            visibility: visible;
          }
        }

        .trigger-close-icon-wrapper {
          position: absolute;
          right: 0;
          top: 0;
          padding: 7px;
          display: none;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;

          .trigger-close-icon {
            pointer-events: none;
            width: 10px;
            height: 10px;
            color: #a8aebb;
          }
        }

        .planner-item-container {
          pointer-events: none;

          .auto-card-heading {
            @include flexRow;
            align-items: center;
            justify-content: flex-start;
            pointer-events: none;

            .auto-card-icon {
              margin-right: 8px;

              &.contacts-icon {
                width: 17px;
                height: 17px;
                margin-top: -6px;
              }

              &.other-icon {
                width: 17px;
                margin-top: -7px;
              }

              &.credit-card-icon {
                margin-top: -4px;
              }

              &.birthday-icon {
                width: 21px;
                height: 21px;
                margin-top: -5px;
                margin-right: 7px;
              }

              &.calendar-icon {
                width: 16px;
              }
            }
          }
        }

        .card-placeholder-wrapper {
          position: absolute;
          height: 50px;
          width: $autoItemWidth;
          opacity: 0;
          visibility: hidden;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          border-radius: 3px;
          box-shadow: 0 2px 6px 0 $shadow;
          @include flexRow;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .intro-placeholder-wrapper {
      @include flexRow;
      width: $autoItemWidth;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 150px;

      .next-item-placeholder {
        color: $blueGrey800;
        opacity: 0.3;
      }
    }
  }
</style>
