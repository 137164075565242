var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeAndReset),expression:"closeAndReset"}],staticClass:"select-search-dropdown",class:[_vm.disabled ? 'disabled' : '', _vm.styleLikeSelect ? 'select-search-dropdown--select' : ''],attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.handleCompareEscape.apply(null, arguments)}}},[_c('div',{ref:"select-button",staticClass:"select-search-dropdown__button",class:[
           _vm.isDropdownOpen ? 'select-search-dropdown__button--active' : '',
           _vm.isEmpty ? 'select-search-dropdown__button--empty' : ''],on:{"click":_vm.toggleDropdown}},[_c('ar-text',{staticClass:"select-search-dropdown__text",class:[_vm.isEmpty ? 'select-search-dropdown__text--empty' : ''],attrs:{"size":"xs","text":_vm.defaultLabel,"multiple-lines":"","max-lines":1,"line-height":"20px"}}),_vm._v(" "),_c('ar-icon',{staticClass:"icon-arrow",attrs:{"width":"10px","name":"arrow","rotate":_vm.isDropdownOpen ? 180 : 0}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDropdownOpen),expression:"isDropdownOpen"}],staticClass:"select-search-dropdown__items-wrapper",style:(_vm.buttonStyles)},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"select-search-dropdown__container"},[(!_vm.offSearch)?_c('am2-search',{staticClass:"select-search-dropdown__search",attrs:{"placeholder":"Search events..."},on:{"input":_vm.handleSearch},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"select-search-dropdown__option-wrapper",class:{ 'select-search-dropdown__option-wrapper--no-search': _vm.offSearch},on:{"scroll":_vm.handleScrollOptionWrapper}},[(_vm.options.length)?[_c('div',{staticClass:"scroll-gradient-top"}),_vm._v(" "),(_vm.enableAny)?_c('a',{class:[
              'select-search-dropdown__option',
              _vm.selectedIdsKeys.length === 0 && 'selected'
            ],on:{"click":function($event){return _vm.handleClickOption('any')}}},[_c('ar-text',{class:[
                'select-search-events-option__event-name',
                'u-margin-top-2',
                'u-margin-bottom-2' ],attrs:{"size":"xs","text":_vm.anyOptionText,"multiple-lines":"","max-lines":1,"line-height":"20px","weight":_vm.selectedIdsKeys.length === 0 ? 'bold' : null}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function(option){return _c('a',{key:("select-search-dropdown-option-" + (option.oid)),staticClass:"select-search-dropdown__option",on:{"click":function($event){return _vm.handleClickOption(option.oid)}}},[_c('ar-checkbox',{staticClass:"select-search-dropdown__checkbox",attrs:{"value":_vm.selectedIds[option.oid]}}),_vm._v(" "),_vm._t("item",null,{"option":option})],2)})]:_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"select-search-dropdown__loading"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):_vm._e(),_vm._v(" "),(!_vm.isLoading && !_vm.options.length)?[_c('ar-text',{staticClass:"select-search-dropdown__no-results",attrs:{"size":"xs","text":"No results found","multiple-lines":"","max-lines":1,"line-height":"20px"}})]:_vm._e()],2)],1),_vm._v(" "),_c('div',{staticClass:"select-search-dropdown__bottom"},[(_vm.warningMessage)?_c('div',{staticClass:"select-search-dropdown__bottom-warning"},[_c('ar-icon',{style:({
            color: _vm.$arStyle.color.orange500,
            marginRight: '8px',
          }),attrs:{"name":"exclamation"}}),_vm._v(" "),_c('ar-text',{style:({
            color: _vm.$arStyle.color.orange500,
          }),attrs:{"size":"xs","text":_vm.warningMessage,"multiple-lines":""}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"select-search-dropdown__bottom-line"},[(_vm.isShowDeselect)?_c('ar-link-button',{staticClass:"select-search-dropdown__deselect",attrs:{"text":"Deselect all","has-underline":true,"color":_vm.$arStyle.color.$purple500},on:{"click":_vm.handleClickDeselect}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticClass:"select-search-dropdown__finish",attrs:{"text":_vm.textSelect},on:{"click":_vm.handleClickFinish}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"triangle"},[_c('div',{staticClass:"triangle-outer"}),_vm._v(" "),_c('div',{staticClass:"triangle-inner"})])}]

export { render, staticRenderFns }